import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import TopNav from "../../components/navbar/TopNav";
import SideNavBar from "../../components/sidebar/SideNavBar";
import { FiEdit } from "react-icons/fi";
import { FaChartPie } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { toast } from "react-toastify";
import { RiDeleteBin4Line } from "react-icons/ri";
import {
  addTehsilAPI,
  deleteTehsilAPI,
  getDistrictListAPI,
  getTehsilListAPI,
  updateTehsilAPI,
} from "../../api/ApiCall";
import Pagination from "../../components/common/pagination/Pagination";
import { regexALPHA } from "../../constant/Constants";
import $ from "jquery";
import "datatables.net";

const TehsilScreen = () => {
  // Add the following state variables to your component's state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Set the number of items per page
  const [districtList, setDistrictList] = useState([]);
  const [stateList, setStateList] = useState([{ id: 10, name: "Bihar" }]);
  const [tehsilList, setTehsilList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [tehsilName, setTehsilName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [timer, setTimer] = useState(5);
  const [userLoader, setUserLoader] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const configRef = useRef(null);
  const datatableRef = useRef(null);
  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setUserLoader(false);
      setUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    getDistrict();
  }, []);
  useEffect(() => {
    if (datatableRef.current) {
      $(datatableRef.current).DataTable().destroy();
      $(datatableRef.current).DataTable();
    }
  }, [tehsilList]);
  const getDistrict = () => {
    setIsLoading(true);
    getDistrictListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          // setIsLoading(false);
          setDistrictList(res.data.data);
          // toast.success(res.data.message);
        } else {
          // setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  useEffect(() => {
    getTehsilList();
  }, []);
  const getTehsilList = () => {
    setIsLoading(true);
    getTehsilListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setTehsilList(res.data.data);
          if (datatableRef.current) {
            $(datatableRef.current).DataTable().destroy();
          }
          $(datatableRef.current).DataTable();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error("500 internal server error");
        setIsLoading(false);
      });
  };

  const openDelete = (item) => {
    setIsOpen(true);
    setData(item);
    setTehsilName(item.tehsil_name);
  };
  const closeDelete = () => {
    setIsOpen(false);
    setTehsilName("");
  };

  const handleTehsilDelete = (item) => {
    const data = {
      tehsil_id: item.tehsil_id,
    };
    setIsLoading(true);
    deleteTehsilAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          closeDelete();
          toast.success(res.data.message);
          getTehsilList();
          // setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const handleShow = (item) => {
    setData(item);
    setShow(true);
    if (item.state_id === 10) {
      setDistrictId(item.district_id);
      setStateId(item.state_id);
      setTehsilName(item.tehsil_name);
    } else {
      setDistrictId("");
      setStateId(10);
    }
  };
  const handleClose = () => {
    setShow(false);
    setDistrictName("");
    setTehsilName("");
    setData([]);
  };
  const handleTehsilSave = (item) => {
    if (districtId === "") {
      toast.error("please select district");
    } else if (!regexALPHA.test(tehsilName)) {
      toast.error("please enter a valid tehsil name.");
      setIsLoading(false);
    } else if (tehsilName === "") {
      toast.error("please enter tehsil name");
      setIsLoading(false);
    } else if (tehsilName === undefined || tehsilName === "undefined") {
      toast.error("please enter a valid  tehsil name");
      setIsLoading(false);
    } else if (tehsilName === null || tehsilName === "null") {
      toast.error("please enter a valid tehsil name");
      setIsLoading(false);
    } else {
      const data = {
        state_id: stateId,
        district_id: districtId,
        block_name: tehsilName,
      };
      setIsLoading(true);
      addTehsilAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            getTehsilList();
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };
  const handleStateChange = (e) => {
    setStateId(e.target.value);
  };
  const handleTehsilUpdate = (item) => {
    console.log("Tehsil name", tehsilName);
    if (districtId === "") {
      toast.error("please select district");
    } else if (!regexALPHA.test(tehsilName)) {
      toast.error("please enter a valid tehsil name.");
      setIsLoading(false);
    } else if (tehsilName === "") {
      toast.error("please enter tehsil name");
      setIsLoading(false);
    } else if (tehsilName === undefined || tehsilName === "undefined") {
      toast.error("please enter a valid tehsil name..");
      setIsLoading(false);
    } else if (tehsilName === null || tehsilName === "null") {
      toast.error("please enter a valid tehsil name...");
      setIsLoading(false);
    } else {
      setIsLoading(true);
      const data = {
        state_id: stateId,
        district_id: districtId,
        block_name: tehsilName,
        tehsil_id: item.tehsil_id,
      };
      updateTehsilAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            getTehsilList();
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  // Pagination
  const totalPages = Math.ceil(tehsilList.length / itemsPerPage); // Total number of pages
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <TopNav />
      <SideNavBar />
      {userLoader ? (
        <div className="p-5" style={{}}>
          <p style={{ color: "crimson", textAlign: "center" }}>
            {"Unauthorized"}
          </p>
          <p style={{ textAlign: "center" }}>
            You will be redirected to the home page in{" "}
            <b style={{ color: "crimson" }}>{timer} </b> seconds.
          </p>
        </div>
      ) : (
        ""
      )}
      {isLoading ? (
        <>
          <FullScreenLoader />
          {userLoader ? (
            ""
          ) : (
            <div className="wrapper">
              <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="container-fluid">
                  <div className="row mb-2">
                    {/* <div className="col-sm-6">
        <h1 className="m-0">Officer Dashboard</h1>
      </div> */}
                    {/* /.col */}
                    <div className="col-sm-12">
                      <ol className="breadcrumb float-sm-right">
                        {user.profile_data.user_type === "S" ? (
                          <li className="breadcrumb-item">
                            <Link
                              to={`${"/fpc-list"}`}
                              style={{ textDecoration: "none" }}
                            >
                              Home
                            </Link>
                          </li>
                        ) : (
                          <li className="breadcrumb-item">
                            <Link
                              to={`${"/dashboard"}/${
                                user.profile_data.user_type === "E"
                                  ? "admin"
                                  : user.profile_data.user_type === "V"
                                  ? "officer"
                                  : user.profile_data.user_type === "O"
                                  ? "field-officer"
                                  : user.profile_data.user_type === "S"
                                  ? "state-officer"
                                  : ""
                              }`}
                              style={{ textDecoration: "none" }}
                            >
                              Home
                            </Link>
                          </li>
                        )}
                        <li className="breadcrumb-item active">tehsil</li>
                      </ol>
                    </div>
                    {/* /.col */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.content-header */}
                <Button
                  className="ml-3 mb-3"
                  variant="primary"
                  style={{ cursor: "pointer" }}
                  onClick={handleShow}
                >
                  Add Tehsil
                </Button>
                {/* Main content */}
                <section className="content">
                  <div className="container-fluid">
                    {/* Dashboard card component */}
                    {/* Main row */}
                    <div className="row">
                      {/* Left col */}
                      <section className="col-lg-12 connectedSortable">
                        {/* Custom tabs (Charts with tabs)*/}
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              {/* <i className="fas fa-chart-pie mr-1" /> */}
                              <FaChartPie
                                style={{ with: 20, height: 20, marginRight: 5 }}
                              />
                              Tehsil List
                            </h4>
                          </div>
                          {/* /.card-header */}
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-bordered table-striped">
                                <thead>
                                  <tr role="row" className="bg-secondary">
                                    <th>S.No</th>
                                    <th>State</th>
                                    <th>District</th>
                                    <th>Tehsil Name</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {tehsilList.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td value={item.state_id}>
                                        {item.state_name}
                                      </td>
                                      <td value={item.district_id}>
                                        {item.district_name}
                                      </td>
                                      <td value={item.tehsil_id}>
                                        {item.tehsil_name}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FiEdit
                                          className="edit-button mr-3"
                                          onClick={() => handleShow(item)}
                                          style={{
                                            with: 20,
                                            height: 20,
                                          }}
                                        />
                                        <RiDeleteBin4Line
                                          className="delete-button"
                                          onClick={() => openDelete(item)}
                                          style={{ with: 20, height: 20 }}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* /.card-body */}
                        </div>
                        {/* /.card */}
                      </section>

                      {/* /.Left col */}
                    </div>
                    {/* /.row (main row) */}
                  </div>
                  {/* /.container-fluid */}
                </section>
                {/* /.content */}
              </div>
              <aside className="control-sidebar control-sidebar-dark"></aside>
            </div>
          )}
        </>
      ) : (
        <div className="wrapper">
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="container-fluid">
              <div className="row mb-2">
                {/* <div className="col-sm-6">
        <h1 className="m-0">Officer Dashboard</h1>
      </div> */}
                {/* /.col */}
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-right">
                    {user.profile_data.user_type === "S" ? (
                      <li className="breadcrumb-item">
                        <Link
                          to={`${"/fpc-list"}`}
                          style={{ textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </li>
                    ) : (
                      <li className="breadcrumb-item">
                        <Link
                          to={`${"/dashboard"}/${
                            user.profile_data.user_type === "E"
                              ? "admin"
                              : user.profile_data.user_type === "V"
                              ? "officer"
                              : user.profile_data.user_type === "O"
                              ? "field-officer"
                              : user.profile_data.user_type === "S"
                              ? "state-officer"
                              : ""
                          }`}
                          style={{ textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </li>
                    )}
                    <li className="breadcrumb-item active">tehsil</li>
                  </ol>
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.content-header */}
            <Button
              className="ml-3 mb-3"
              variant="primary"
              style={{ cursor: "pointer" }}
              onClick={handleShow}
            >
              Add Tehsil
            </Button>

            {/* Main content */}
            <section className="content">
              <div className="container-fluid">
                {/* Dashboard card component */}
                {/* Main row */}
                <div className="row">
                  {/* Left col */}
                  <section className="col-lg-12 connectedSortable">
                    {/* Custom tabs (Charts with tabs)*/}
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">
                          {/* <i className="fas fa-chart-pie mr-1" /> */}
                          <FaChartPie
                            style={{ with: 20, height: 20, marginRight: 5 }}
                          />
                          Tehsil List
                        </h4>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            ref={datatableRef}
                            className="table table-bordered table-striped"
                          >
                            <thead>
                              <tr role="row" className="bg-secondary">
                                <th>S.No</th>
                                <th>State</th>
                                <th>District</th>
                                <th>Tehsil Name</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tehsilList
                                // .slice(
                                //   (currentPage - 1) * itemsPerPage,
                                //   currentPage * itemsPerPage
                                // )
                                .map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      {index + 1}
                                      {/* {(currentPage - 1) * itemsPerPage +
                                        index +
                                        1} */}
                                    </td>
                                    <td value={item.state_id}>
                                      {item.state_name}
                                    </td>
                                    <td value={item.district_id}>
                                      {item.district_name}
                                    </td>
                                    <td value={item.tehsil_id}>
                                      {item.tehsil_name}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <FiEdit
                                        className="edit-button mr-3"
                                        onClick={() => handleShow(item)}
                                        style={{
                                          with: 20,
                                          height: 20,
                                        }}
                                      />
                                      <RiDeleteBin4Line
                                        className="delete-button"
                                        onClick={() => openDelete(item)}
                                        style={{ with: 20, height: 20 }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* /.card-body */}
                    </div>
                    {/* /.card */}
                  </section>

                  {/* /.Left col */}
                </div>
                {/* /.row (main row) */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}

            {/* /.content */}
          </div>
          <aside className="control-sidebar control-sidebar-dark"></aside>
        </div>
      )}
      <Footer />
      <Modal show={show}>
        <Modal.Header>
          {!data.tName ? (
            <Modal.Title>Add Tehsil</Modal.Title>
          ) : (
            <Modal.Title>Update Tehsil</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>State Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleStateChange}
                value={stateId}
              >
                {stateList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>District Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setDistrictId(e.target.value)}
                value={districtId}
              >
                <option value="">Select One</option>
                {districtList.map((item, index) => (
                  <option key={index} value={item.district_id}>
                    {item.district_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Tehsil Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="tehsil name"
                value={tehsilName}
                onChange={(e) => setTehsilName(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!data.tehsil_name ? (
            <Button variant="primary" onClick={() => handleTehsilSave(data)}>
              Save
            </Button>
          ) : (
            <Button variant="primary" onClick={() => handleTehsilUpdate(data)}>
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>
            <Modal.Title>Delete Tehsil</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Tehsil Name</Form.Label>
              <Form.Control
                style={{ borderColor: "crimson" }}
                type="text"
                placeholder="tehsil name"
                value={tehsilName}
                autoFocus
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDelete}>
            Close
          </Button>

          <Button variant="danger" onClick={() => handleTehsilDelete(data)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TehsilScreen;
