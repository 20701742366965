import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import TopNav from "../../components/navbar/TopNav";
import SideNavBar from "../../components/sidebar/SideNavBar";
import { FiEdit, FiEye } from "react-icons/fi";
import { FaChartPie } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { RiDeleteBin4Line } from "react-icons/ri";
import {
  addPanchayatAPI,
  deletePanchayatAPI,
  getDistrictListAPI,
  getPanchayatListAPI,
  getTehsilDropDownListAPI,
  getTehsilListAPI,
  testPanchayatPagination,
  testPanchayatSearch,
  updatePanchayatAPI,
} from "../../api/ApiCall";
import Pagination from "../../components/common/pagination/Pagination";
import { regexALPHA } from "../../constant/Constants";
import $ from "jquery";
import "datatables.net";

const gramData = [
  {
    tId: 1,
    tName: "Tehsil-1",
    dId: 1,
    dName: "Ara",
    sId: 1,
    sName: "Bihar",
    gId: 1,
    gName: "Gram-1",
  },
  {
    tId: 2,
    tName: "Tehsil-2",
    dId: 2,
    dName: "Siwan",
    sId: 1,
    sName: "Bihar",
    gId: 2,
    gName: "Gram-2",
  },
  {
    tId: 3,
    tName: "Tehsil-3",
    dId: 3,
    dName: "Patna",
    sId: 1,
    sName: "Bihar",
    gId: 3,
    gName: "Gram-3",
  },
];

const GrampanchayatScreen = () => {
  // Add the following state variables to your component's state
  const [currentPage, setCurrentPage] = useState(1);
  const [tPage, setTPage] = useState("");
  const [itemsPerPage] = useState(25); // Set the number of items per page
  const [gData, setGData] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [stateList, setStateList] = useState([{ id: 10, name: "Bihar" }]);
  const [tehsilList, setTehsilList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [stateId, setStateId] = useState("10");
  const [districtId, setDistrictId] = useState("");
  const [tehsilId, setTehsilId] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [grampanchayatName, setGrampanchayatName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState(5);
  const [userLoader, setUserLoader] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const configRef = useRef(null);
  const datatableRef = useRef(null);
  const [filteredItem, setFilteredItem] = useState(null);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setUserLoader(false);
      setUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    getTehsil();
    getDistrict();
    panchayatList();
  }, []);

  const getTehsil = () => {
    setIsLoading(true);
    getTehsilListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setTehsilList(res.data.data);
        } else {
          toast.error(res.data.error);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const getDistrict = () => {
    setIsLoading(true);
    getDistrictListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setDistrictList(res.data.data);
          // toast.success(res.data.message);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const panchayatList = () => {
    setIsLoading(true);
    const p = {
      page: currentPage,
      panchyat_search: searchText,
    };
    testPanchayatSearch(p)
      // getPanchayatListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setTPage(res.data.total_pages);
          setGData(res.data.data);
          // if (datatableRef.current) {
          //   $(datatableRef.current).DataTable().destroy();
          // }
          // $(datatableRef.current).DataTable();
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  useEffect(() => {
    if (datatableRef.current) {
      $(datatableRef.current).DataTable().destroy();
      $(datatableRef.current).DataTable();
    }
  }, [gData]);
  const openDelete = (item) => {
    console.log("delete-->", item);
    setIsOpen(true);
    setData(item);
    setGrampanchayatName(item.panchayat_name);
  };
  const closeDelete = () => {
    setIsOpen(false);
    setGrampanchayatName("");
  };
  const handleShow = (item) => {
    console.log("____", item);
    setShow(true);
    const t_id = item.block_id;
    console.log("====", t_id);
    setData(item);
    if (item.state_id === 10) {
      console.log("Stae_id--->", t_id);
      setDistrictId(item.district_id);
      setStateId(10);
      setTehsilId(t_id);
      setGrampanchayatName(item.panchayat_name);
      handleDistrictChange(item.district_id);
    } else {
      setDistrictId("");
      setStateId(10);
      setTehsilId("");
      setGrampanchayatName(grampanchayatName);
    }
  };
  console.log("TID===>", tehsilId);
  const handleClose = () => {
    setShow(false);
    setDistrictName("");
    setDistrictId("");
    setStateId("");
    setTehsilId("");
    setGrampanchayatName("");
    setData([]);
  };
  const handleStateChange = (e) => {
    setStateId(e.target.value);
    console.log("State", e.target.value);
  };
  const handleDistrictChange = async (e) => {
    setTehsilList([]);
    let sel = "";
    if (typeof e === "number") {
      sel = e;
    } else {
      sel = e.target.value;
    }
    setDistrictId(sel);

    const data = {
      district_id: sel,
    };
    await getTehsilDropDownListAPI(data, configRef.current)
      .then((res) => {
        setIsLoading(true);
        if (res.data.status === 200) {
          setIsLoading(false);
          setTehsilList(res.data.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  };
  const handleGrampanchayatSave = (item) => {
    if (districtId === "") {
      toast.error("please select district");
    } else if (tehsilId === "") {
      toast.error("please select tehsil");
    } else if (!regexALPHA.test(grampanchayatName)) {
      toast.error("please enter valid gram panchayat name");
      setIsLoading(false);
    } else if (grampanchayatName === "") {
      toast.error("please enter gram gram panchayat name");
      setIsLoading(false);
    } else if (
      grampanchayatName === "undefined" ||
      grampanchayatName === undefined
    ) {
      toast.error("please enter a valid gram panchayat name");
      setIsLoading(false);
    } else if (grampanchayatName === "null" || grampanchayatName === null) {
      toast.error("please enter a valid gram panchayat name");
      setIsLoading(false);
    } else {
      const data = {
        state: stateId,
        district: districtId,
        block_id: tehsilId,
        panchayat_name: grampanchayatName,
      };
      setIsLoading(true);
      addPanchayatAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            panchayatList(); //hit gramList api
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };
  const handleGrampanchayatUpdate = (item) => {
    if (districtId === "") {
      toast.error("please select district");
    } else if (tehsilId === "") {
      toast.error("please select tehsil");
    } else if (!regexALPHA.test(grampanchayatName)) {
      toast.error("please enter valid gram panchayat name");
      setIsLoading(false);
    } else if (grampanchayatName === "") {
      toast.error("please enter  gram panchayat name");
      setIsLoading(false);
    } else if (
      grampanchayatName === "undefined" ||
      grampanchayatName === undefined
    ) {
      toast.error("please enter a valid gram panchayat name");
      setIsLoading(false);
    } else if (grampanchayatName === "null" || grampanchayatName === null) {
      toast.error("please enter a valid gram panchayat name");
      setIsLoading(false);
    } else {
      const data = {
        state: stateId,
        district: districtId,
        block_id: tehsilId,
        panchayat_name: grampanchayatName,
        panchayat_id: item.panchayat_id,
      };
      setIsLoading(true);
      updatePanchayatAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            panchayatList();
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };
  const handleGrampanchayatDelete = (item) => {
    const data = {
      panchayat_id: item.panchayat_id,
    };
    setIsLoading(true);
    deletePanchayatAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          closeDelete();
          toast.success(res.data.message);
          panchayatList();
          // setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };
  // Pagination
  //const totalPages = Math.ceil(gData.length / itemsPerPage); // Total number of pages
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    test(pageNumber, searchText);
  };
  const test = (a, searchText) => {
    setIsLoading(true);
    const p = {
      page: a,
      panchyat_search: searchText,
    };
    testPanchayatSearch(p)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setTPage(res.data.total_pages);
          setCurrentPage(res.data.current_page);
          setGData(res.data.data);
          console.log("Data===>", res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const handleSearch = (e) => {
    setIsLoading(true);
    // const i = e.target.value;
    // setSearchText(e.target.value);
    console.log("Filter text", searchText);
    const d = {
      page: currentPage > 1 ? 1 : currentPage,
      panchyat_search: searchText,
    };
    testPanchayatSearch(d)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setGData(res.data.data);
          setTPage(res.data.total_pages);
          setCurrentPage(res.data.current_page);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
          setFilteredItem(null);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleCloseSearch = () => {
    setIsLoading(true);
    let a = "";
    setSearchText(a);
    const d = {
      page: "",
      panchyat_search: "",
    };
    testPanchayatSearch(d)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setGData(res.data.data);
          setTPage(res.data.total_pages);
          setCurrentPage(res.data.current_page);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
          setFilteredItem(null);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      console.log("If", e.target.value);
      handleCloseSearch();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  return (
    <div>
      {isLoading ? <FullScreenLoader /> : ""}
      <TopNav />
      <SideNavBar />
      {userLoader ? (
        <div className="p-5" style={{}}>
          <p style={{ color: "crimson", textAlign: "center" }}>
            {"Unauthorized"}
          </p>
          <p style={{ textAlign: "center" }}>
            You will be redirected to the home page in{" "}
            <b style={{ color: "crimson" }}>{timer} </b> seconds.
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="wrapper">
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="container-fluid">
            <div className="row mb-2">
              {/* <div className="col-sm-6">
        <h1 className="m-0">Officer Dashboard</h1>
      </div> */}
              {/* /.col */}
              <div className="col-sm-12">
                <ol className="breadcrumb float-sm-right">
                  {user.profile_data.user_type === "S" ? (
                    <li className="breadcrumb-item">
                      <Link
                        to={`${"/fpc-list"}`}
                        style={{ textDecoration: "none" }}
                      >
                        Home
                      </Link>
                    </li>
                  ) : (
                    <li className="breadcrumb-item">
                      <Link
                        to={`${"/dashboard"}/${
                          user.profile_data.user_type === "E"
                            ? "admin"
                            : user.profile_data.user_type === "V"
                            ? "officer"
                            : user.profile_data.user_type === "O"
                            ? "field-officer"
                            : user.profile_data.user_type === "S"
                            ? "state-officer"
                            : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Home
                      </Link>
                    </li>
                  )}
                  <li className="breadcrumb-item active">gram-panchayat</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.content-header */}
          <Button
            className="ml-3 mb-3"
            variant="primary"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            Add Gram Panchayat
          </Button>
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              {/* Dashboard card component */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <section className="col-lg-12 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div
                      className="d-flex p-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FaChartPie
                          style={{ with: 20, height: 20, marginRight: 5 }}
                        />
                        <span style={{ fontSize: "1.1rem", fontWeight: 400 }}>
                          Gram Panchayat List
                        </span>
                      </div>
                      <div
                        style={{ float: "right" }}
                        id="DataTables_Table_2_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="p-1"
                            style={{
                              border: "1px solid #bfbfbf",
                              marginLeft: "2px",
                              borderRadius: "3px",
                            }}
                            value={searchText}
                            onChange={handleChange}
                            placeholder=""
                            onKeyDown={handleKeyDown}
                            aria-controls="DataTables_Table_2"
                          />
                        </label>
                      </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          // ref={datatableRef}
                          className="table table-bordered table-striped"
                        >
                          <thead>
                            <tr role="row" className="bg-secondary">
                              <th>S.No</th>
                              <th>State</th>
                              <th>District</th>
                              <th>Tehsil</th>
                              <th>Gram Panchayat</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {gData
                              // .slice(
                              //   (currentPage - 1) * itemsPerPage,
                              //   currentPage * itemsPerPage
                              // )
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    {index + 1}
                                    {/* {(currentPage - 1) * itemsPerPage +
                                      index +
                                      1} */}
                                  </td>
                                  <td value={item.state_id}>
                                    {item.state_name}
                                  </td>
                                  <td value={item.district_id}>
                                    {item.district_name}
                                  </td>
                                  <td value={item.tehsil_id}>
                                    {item.tehsil_name}
                                  </td>
                                  <td value={item.panchayat_id}>
                                    {item.panchayat_name}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <FiEdit
                                      className="edit-button mr-3"
                                      onClick={() => handleShow(item)}
                                      style={{
                                        with: 20,
                                        height: 20,
                                      }}
                                    />
                                    <RiDeleteBin4Line
                                      className="delete-button"
                                      onClick={() => openDelete(item)}
                                      style={{ with: 20, height: 20 }}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </section>

                {/* /.Left col */}
              </div>
              {/* /.row (main row) */}
            </div>
            {/* /.container-fluid */}
          </section>
          <Pagination
            currentPage={currentPage}
            totalPages={tPage}
            onPageChange={handlePageChange}
          />
          {/* /.content */}
          {/* <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          /> */}
        </div>
        <aside className="control-sidebar control-sidebar-dark"></aside>
      </div>

      <Footer />
      <Modal show={show}>
        <Modal.Header>
          {!data.panchayat_name ? (
            <Modal.Title>Add Gram Panchayat</Modal.Title>
          ) : (
            <Modal.Title>Update Gram Panchayat</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>State Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleStateChange}
                value={stateId}
              >
                {stateList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>District Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleDistrictChange}
                value={districtId}
              >
                <option value="">Select One</option>
                {districtList.map((item, index) => (
                  <option key={index} value={item.district_id}>
                    {item.district_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Tehsil/Block Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setTehsilId(e.target.value)}
                value={tehsilId}
              >
                <option value="">Select One</option>
                {tehsilList.map((item, index) => (
                  <option key={index} value={item.tehsil_id}>
                    {item.tehsil_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Gram Panchayat Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="gram panchayat name"
                value={grampanchayatName}
                onChange={(e) => setGrampanchayatName(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!data.panchayat_name ? (
            <Button
              variant="primary"
              onClick={() => handleGrampanchayatSave(data)}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => handleGrampanchayatUpdate(data)}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>
            <Modal.Title>Delete Grampanchayat</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Gram Panchayat Name</Form.Label>
              <Form.Control
                style={{ borderColor: "crimson" }}
                type="text"
                placeholder="gram panchayat name"
                value={grampanchayatName}
                autoFocus
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDelete}>
            Close
          </Button>

          <Button
            variant="danger"
            onClick={() => handleGrampanchayatDelete(data)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GrampanchayatScreen;
