import React, { useState } from "react";
import AdminLTELogo from "../../assets/images/AdminLTELogo.png";
import user_profile from "../../assets/images/avatar5.png";
import { AiFillDashboard } from "react-icons/ai";
import { MdOutlineApps } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";

const SideNavBar = (props) => {
  const params = useParams();
  const navigate = useNavigate;
  const user = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      {user === null ? (
        <Link className="brand-link" to="/">
          <img
            src={AdminLTELogo}
            alt="AdminLTE Logo"
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8", maxHeight: "33px", width: "auto" }}
          />
          <span className="brand-text font-weight-light">JSPVAT-MIS</span>
        </Link>
      ) : (
        <>
          {user.profile_data.user_type === "S" ? (
            <Link to={`${"/fpc-list"}`} className="brand-link">
              <img
                src={AdminLTELogo}
                alt="AdminLTE Logo"
                className="brand-image img-circle elevation-3"
                style={{ opacity: ".8" }}
              />
              <span className="brand-text font-weight-light">JSPVAT-MIS</span>
            </Link>
          ) : user.profile_data.user_type === "V" ? (
            <Link to={`${"/block-list"}`} className="brand-link">
              <img
                src={AdminLTELogo}
                alt="AdminLTE Logo"
                className="brand-image img-circle elevation-3"
                style={{ opacity: ".8" }}
              />
              <span className="brand-text font-weight-light">JSPVAT-MIS</span>
            </Link>
          ) : (
            <Link
              to={`${"/dashboard"}/${
                user.profile_data.user_type === "E"
                  ? "admin"
                  : user.profile_data.user_type === "V"
                  ? "officer"
                  : user.profile_data.user_type === "O"
                  ? "field-officer"
                  : user.profile_data.user_type === "S"
                  ? "state-officer"
                  : ""
              }`}
              className="brand-link"
            >
              <img
                src={AdminLTELogo}
                alt="AdminLTE Logo"
                className="brand-image img-circle elevation-3"
                style={{ opacity: ".8" }}
              />
              <span className="brand-text font-weight-light">JSPVAT-MIS</span>
            </Link>
          )}
        </>
      )}
      {user === null ? (
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Add icons to the links using the .nav-icon class
        with font-awesome or any other icon font library */}
            <li className="nav-item menu-open">
              <a href="" className="nav-link active">
                {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                <AiFillDashboard
                  style={{
                    width: "1.4rem",
                    height: "1.4rem",
                    marginRight: "0.5rem",
                  }}
                />
                <p>Error</p>
              </a>
            </li>
          </ul>
        </nav>
      ) : (
        <>
          {user.profile_data.user_type === "E" ? (
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img
                    src={user_profile}
                    className="img-circle elevation-2"
                    alt="User Profile"
                  />
                </div>
                <div className="info">
                  <a href="#" className="d-block">
                    {user.profile_data.name}
                    {/* Himanshu Kumar */}
                    <label
                      className="d-block"
                      style={{
                        fontSize: "0.8rem",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {user.profile_data.user_type === "E" ? "admin" : ""}
                      {/* {"admin"} */}
                    </label>
                  </a>
                </div>
              </div>

              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {/* Add icons to the links using the .nav-icon class
            with font-awesome or any other icon font library */}
                  <li className="nav-item menu-open">
                    <Link
                      to={`${"/dashboard"}/${
                        user.profile_data.user_type === "E"
                          ? "admin"
                          : user.profile_data.user_type === "V"
                          ? "officer"
                          : user.profile_data.user_type === "O"
                          ? "field-officer"
                          : user.profile_data.user_type === "S"
                          ? "state-officer"
                          : ""
                      }`}
                      className="nav-link"
                    >
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Dashboard</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/district" className="nav-link">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>District</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/tehsil" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Tehsil/Block</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/grampanchayat" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Gram Panchayat</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/village" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Village</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/commodity" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Commodity</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/unit" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Units</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/category" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Category</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/user" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>User</p>
                    </Link>
                  </li>
                  {/* <li className="nav-item menu-open">
                    <Link to="/admin/entry-form" className="nav-link ">
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Entry Form</p>
                    </Link>
                  </li> */}
                  <li className="nav-item menu-open">
                    <Link to="/admin/kyc" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>KYC</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/fpc" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>FPC</p>
                    </Link>
                  </li>
                  <li className="nav-item menu-open">
                    <Link to="/admin/reject" className="nav-link ">
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Reject</p>
                    </Link>
                  </li>
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
          ) : user.profile_data.user_type === "V" ? (
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img
                    src={user_profile}
                    className="img-circle elevation-2"
                    alt="User Profile"
                  />
                </div>
                <div className="info">
                  <a href="#" className="d-block">
                    {user.profile_data.name}
                    {/* Himanshu Kumar */}
                    <label
                      className="d-block"
                      style={{
                        fontSize: "0.8rem",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {user.profile_data.user_type === "V" ? "officer" : ""}
                      {/* {"admin"} */}
                    </label>
                  </a>
                </div>
              </div>

              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {/* Add icons to the links using the .nav-icon class
       with font-awesome or any other icon font library */}
                  {user.profile_data.user_type === "V" ? (
                    <li className="nav-item menu-open">
                      <Link to={`${"/block-list"}`} className="nav-link">
                        {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                        <MdOutlineApps
                          style={{
                            width: "1.4rem",
                            height: "1.4rem",
                            marginRight: "0.5rem",
                          }}
                        />
                        <p>Dashboard</p>
                      </Link>
                    </li>
                  ) : (
                    <li className="nav-item menu-open">
                      <Link
                        to={`${"/dashboard"}/${
                          user.profile_data.user_type === "E"
                            ? "admin"
                            : user.profile_data.user_type === "V"
                            ? "officer"
                            : user.profile_data.user_type === "O"
                            ? "field-officer"
                            : user.profile_data.user_type === "S"
                            ? "state-officer"
                            : ""
                        }`}
                        className="nav-link"
                      >
                        {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                        <MdOutlineApps
                          style={{
                            width: "1.4rem",
                            height: "1.4rem",
                            marginRight: "0.5rem",
                          }}
                        />
                        <p>Dashboard</p>
                      </Link>
                    </li>
                  )}
                  {/* <li className="nav-item menu-open">
                    <a href="index.html" className="nav-link active">
                      <AiFillDashboard
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Change Application Status</p>
                    </a>
                  </li> */}
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
          ) : user.profile_data.user_type === "O" ? (
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img
                    src={user_profile}
                    className="img-circle elevation-2"
                    alt="User Profile"
                  />
                </div>
                <div className="info">
                  <a href="#" className="d-block">
                    {user.profile_data.name}
                    {/* Himanshu Kumar */}
                    <label
                      className="d-block"
                      style={{
                        fontSize: "0.8rem",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {user.profile_data.user_type === "O"
                        ? "field-officer"
                        : ""}
                      {/* {"admin"} */}
                    </label>
                  </a>
                </div>
              </div>

              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {/* Add icons to the links using the .nav-icon class
        with font-awesome or any other icon font library */}
                  <li className="nav-item menu-open">
                    <Link
                      to={`${"/dashboard"}/${
                        user.profile_data.user_type === "E"
                          ? "admin"
                          : user.profile_data.user_type === "V"
                          ? "officer"
                          : user.profile_data.user_type === "O"
                          ? "field-officer"
                          : user.profile_data.user_type === "S"
                          ? "state-officer"
                          : ""
                      }`}
                      className="nav-link "
                    >
                      {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                      <MdOutlineApps
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>Dashboard</p>
                    </Link>
                  </li>
                  {/* <li className="nav-item menu-open">
                    <a href="index.html" className="nav-link active">
                      <AiFillDashboard
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>New Entry</p>
                    </a>
                  </li> */}
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
          ) : user.profile_data.user_type === "S" ? (
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img
                    src={user_profile}
                    className="img-circle elevation-2"
                    alt="User Profile"
                  />
                </div>
                <div className="info">
                  <a href="#" className="d-block">
                    {user.profile_data.name}
                    {/* Himanshu Kumar */}
                    <label
                      className="d-block"
                      style={{
                        fontSize: "0.8rem",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    >
                      {user.profile_data.user_type === "S"
                        ? "state-officer"
                        : ""}
                      {/* {"admin"} */}
                    </label>
                  </a>
                </div>
              </div>

              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {/* Add icons to the links using the .nav-icon class
        with font-awesome or any other icon font library */}
                  {user.profile_data.user_type === "S" ? (
                    <li className="nav-item menu-open">
                      <Link to={`${"/fpc-list"}`} className="nav-link">
                        {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                        <MdOutlineApps
                          style={{
                            width: "1.4rem",
                            height: "1.4rem",
                            marginRight: "0.5rem",
                          }}
                        />
                        <p>Dashboard</p>
                      </Link>
                    </li>
                  ) : (
                    <li className="nav-item menu-open">
                      <Link
                        to={`${"/dashboard"}/${
                          user.profile_data.user_type === "E"
                            ? "admin"
                            : user.profile_data.user_type === "V"
                            ? "officer"
                            : user.profile_data.user_type === "O"
                            ? "field-officer"
                            : user.profile_data.user_type === "S"
                            ? "state-officer"
                            : ""
                        }`}
                        className="nav-link"
                      >
                        {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                        <MdOutlineApps
                          style={{
                            width: "1.4rem",
                            height: "1.4rem",
                            marginRight: "0.5rem",
                          }}
                        />
                        <p>Dashboard</p>
                      </Link>
                    </li>
                  )}
                  {/* <li className="nav-item menu-open">
                    <a href="" className="nav-link active">
                      <AiFillDashboard
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          marginRight: "0.5rem",
                        }}
                      />
                      <p>State Dashboard</p>
                    </a>
                  </li> */}
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
          ) : (
            <nav className="mt-2">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
                {/* Add icons to the links using the .nav-icon class
        with font-awesome or any other icon font library */}
                <li className="nav-item menu-open">
                  <a href="" className="nav-link active">
                    {/* <i className="nav-icon fas fa-tachometer-alt" /> */}
                    <AiFillDashboard
                      style={{
                        width: "1.4rem",
                        height: "1.4rem",
                        marginRight: "0.5rem",
                      }}
                    />
                    <p>Error</p>
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </>
      )}

      {/* /.sidebar */}
    </aside>
  );
};

export default SideNavBar;
