import React, { useEffect, useRef, useState } from "react";
import SideBar from "../components/sidebar/SideBar";
import { AiTwotoneHome } from "react-icons/ai";
import AdminLTELogo from "../assets/images/AdminLTELogo.png";
import { FaChartPie } from "react-icons/fa";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { RiBarChartFill } from "react-icons/ri";
import { FiUserPlus } from "react-icons/fi";
import { AiFillPieChart } from "react-icons/ai";
import HomeCard from "../components/card/HomeCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderBar from "../components/navbar/HeaderBar";
import { AiOutlineDashboard } from "react-icons/ai";
import { FiEdit, FiEye } from "react-icons/fi";
import TopNav from "../components/navbar/TopNav";
import SideNavBar from "../components/sidebar/SideNavBar";
import Footer from "../components/footer/Footer";
import TableData from "../components/table/TableData";
import { ToastContainer, toast } from "react-toastify";
import FullScreenLoader from "../components/loader/FullScreenLoader";
import StateCard from "../components/card/StateCard";
import DistrictList from "./DistrictList";
import TableDataTwo from "../components/table/TableDataTwo";
import { getTableDataByBlockIdAndStatus } from "../api/ApiCall";

const DashboardTwo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const data = location.state;
  const configRef = useRef(null);
  const [dataTable, setDataTable] = useState([]);
  const user = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    if (user === null) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setIsLoading(false);
    }
  }, [timer]);
  useEffect(() => {
    getTableDataByBlockId(data);
  }, []);
  const getTableDataByBlockId = (id) => {
    console.log("Data", id);
    getTableDataByBlockIdAndStatus(id, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          console.table("data", res.data.data);
          setDataTable(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("somthing went wrong");
      });
  };
  const menuItems = [
    {
      title: "Dashboard",
      icon: AiOutlineDashboard,
      url: "/",
    },
    {
      title: "Menu 1",
      icon: AiOutlineDashboard,
      submenus: [
        {
          title: "Submenu 1.1",
          url: "/submenu1-1",
          icon: AiOutlineDashboard,
        },
        {
          title: "Submenu 1.2",
          url: "/submenu1-2",
          icon: AiOutlineDashboard,
        },
      ],
    },
    {
      title: "Menu 2",
      icon: AiOutlineDashboard,
      submenus: [
        {
          title: "Submenu 2.1",
          url: "/submenu2-1",
          icon: AiOutlineDashboard,
        },
        {
          title: "Submenu 2.2",
          url: "/submenu2-2",
          icon: AiOutlineDashboard,
        },
      ],
    },
  ];

  const homeCardData = [
    {
      id: 1,
      value: 50,
      title: "Total Entries",
      icon: HiOutlineShoppingBag,
      color: "#17a2b8",
    },
    {
      id: 1,
      value: 10,
      title: "Pending",
      icon: RiBarChartFill,
      color: "#ffc107",
    },
    {
      id: 3,
      value: 30,
      title: "Approved",
      icon: FiUserPlus,
      color: "#28a745",
    },
    {
      id: 4,
      value: 10,
      title: "Rejected",
      icon: FaChartPie,
      color: "#dc3545",
    },
  ];

  return (
    <div>
      <ToastContainer />

      <TopNav />
      {isLoading ? (
        <>
          <SideNavBar />
          <div className="p-5" style={{}}>
            <p style={{ color: "crimson", textAlign: "center" }}>
              {"Unauthorized"}
            </p>
            <p style={{ textAlign: "center" }}>
              You will be redirected to the home page in{" "}
              <b style={{ color: "crimson" }}>{timer} </b> seconds.
            </p>
          </div>
        </>
      ) : (
        <>
          {" "}
          <SideNavBar />
          <div className="wrapper">
            {/* <TableData data={data} /> */}
            <TableDataTwo data={dataTable} />
            <aside className="control-sidebar control-sidebar-dark"></aside>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default DashboardTwo;
