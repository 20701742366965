import React from "react";
import IconDisplay from "./IconDisplay";

const HomeCard = (props) => {
  return (
   <section className="content">
     <div className="container-fluid">
      <div className="row mb-3">
        {props.data.map((item, index) => (
          <div key={index} className="col-lg-3 col-6">
            <div
              className="small-box d-flex p-4"
              style={{
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: 8,
                backgroundColor: `${item.color}`,
              }}
            >
              <div className="inner pl-2">
                <h3
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                >
                  {item.value}
                </h3>
                <p style={{ fontSize: "0.9rem", color: "#fff" }}>
                  {item.title}
                </p>
              </div>
              <div className="icon">
                <IconDisplay
                  icon={item.icon}
                  width="4.2rem"
                  height="4.2rem"
                  color="#00000026"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
   </section>
  );
};

export default HomeCard;
