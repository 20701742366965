import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import TopNav from "../../components/navbar/TopNav";
import SideNavBar from "../../components/sidebar/SideNavBar";
import { FiEdit, FiEye } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import { FaChartPie } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { toast } from "react-toastify";
import {
  addCategoryAPI,
  createRemarkAPI,
  deleteCategoryAPI,
  deleteRemarkAPI,
  getCategoryListAPI,
  remarkListAPI,
  updateCategoryAPI,
  updateRemarkAPI,
} from "../../api/ApiCall";
import { regexALPHA } from "../../constant/Constants";

export const RejectScreen = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [timer, setTimer] = useState(5);
  const [userLoader, setUserLoader] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const configRef = useRef(null);
  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setUserLoader(false);
      setUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    getCategory();
  }, []);
  const getCategory = () => {
    setIsLoading(true);
    remarkListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          toast.success(res.data.data);
          setCategoryList(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error();
      });
  };

  const openDelete = (item) => {
    setIsOpen(true);
    setData(item);
    setCategoryName(item.reject_reason);
  };
  const closeDelete = () => {
    setIsOpen(false);
  };

  const handleCategoryDelete = (item) => {
    const data = {
      remark_id: item.remark_id,
    };
    setIsLoading(true);
    deleteRemarkAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          closeDelete();
          toast.success(res.data.message);
          getCategory();
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("something went wrong");
      });
  };

  const handleShow = (item) => {
    setShow(true);
    setData(item);
    setCategoryName(item.reject_reason);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleCategorySave = () => {
    if (!regexALPHA.test(categoryName)) {
      toast.error("please enter a valid reject reason");
    } else if (!categoryName) {
      toast.error("please enter a valid reject reason");
    } else if (categoryName === "undefined" || categoryName === undefined) {
      toast.error("please enter a valid reject reason");
    } else if (categoryName === "null" || categoryName === null) {
      toast.error("please enter a valid reject reason");
    } else {
      const data = {
        reject_reason: categoryName,
      };
      setIsLoading(true);
      createRemarkAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            //call category list function
            getCategory();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };
  const handleCategoryUpdate = (item) => {
    if (!regexALPHA.test(categoryName)) {
      toast.error("please enter a valid reject reason");
    } else if (!categoryName) {
      toast.error("please enter a valid reject reason");
    } else if (categoryName === "undefined" || categoryName === undefined) {
      toast.error("please enter a valid reject reason");
    } else if (categoryName === "null" || categoryName === null) {
      toast.error("please enter a valid reject reason");
    } else {
      const data = {
        remark_id: item.remark_id,
        reject_reason: categoryName,
      };
      setIsLoading(true);
      updateRemarkAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            getCategory();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };
  return (
    <div>
      {isLoading ? <FullScreenLoader /> : ""}
      <TopNav />
      <SideNavBar />
      {userLoader ? (
        <div className="p-5" style={{}}>
          <p style={{ color: "crimson", textAlign: "center" }}>
            {"Unauthorized"}
          </p>
          <p style={{ textAlign: "center" }}>
            You will be redirected to the home page in{" "}
            <b style={{ color: "crimson" }}>{timer} </b> seconds.
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="wrapper">
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard" style={{ textDecoration: "none" }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">reject</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.content-header */}
          <Button
            className="ml-3 mb-3"
            variant="primary"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            Add Reject Reason
          </Button>
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              {/* Dashboard card component */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <section className="col-lg-12 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {/* <i className="fas fa-chart-pie mr-1" /> */}
                        <FaChartPie
                          style={{ with: 20, height: 20, marginRight: 5 }}
                        />
                        Reject Reason List
                      </h4>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr role="row" className="bg-secondary">
                              <th>S.No</th>
                              <th>Reject Reason</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {categoryList.map((item, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td value={item.remark_id}>
                                  {item.reject_reason}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  <FiEdit
                                    className="edit-button mr-2"
                                    onClick={() => handleShow(item)}
                                    style={{ with: 30, height: 35 }}
                                  />
                                  <RiDeleteBin4Line
                                    className="delete-button"
                                    onClick={() => openDelete(item)}
                                    style={{ with: 30, height: 30 }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </section>

                {/* /.Left col */}
              </div>
              {/* /.row (main row) */}
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
        <aside className="control-sidebar control-sidebar-dark"></aside>
      </div>

      <Footer />
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>
            {!data.reject_reason ? (
              <Modal.Title>Add Reject Reason</Modal.Title>
            ) : (
              <Modal.Title>Update Reject Reason</Modal.Title>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Reject Reason</Form.Label>
              <Form.Control
                type="text"
                placeholder="reject reason"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!data.reject_reason ? (
            <Button variant="primary" onClick={() => handleCategorySave(data)}>
              Save
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => handleCategoryUpdate(data)}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>
            <Modal.Title>Delete Reject Reason</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Reject Reason</Form.Label>
              <Form.Control
                style={{ borderColor: "crimson" }}
                type="text"
                placeholder="reject reason"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                autoFocus
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDelete}>
            Close
          </Button>

          <Button variant="danger" onClick={() => handleCategoryDelete(data)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
