import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FullScreenLoader from "../components/loader/FullScreenLoader";
import SideNavBar from "../components/sidebar/SideNavBar";
import Footer from "../components/footer/Footer";
import TopNav from "../components/navbar/TopNav";
import { toast } from "react-toastify";
import { dashboardAPI, getBlockDashBoardDataListByFpc } from "../api/ApiCall";
import { FaChartPie } from "react-icons/fa";

const BlockList = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const configRef = useRef(null);
  const params = useParams();
  const [timer, setTimer] = useState(5);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setIsLoading(false);
      setUserLoader(false);
    }
  }, [timer]);
  //   console.log("Params from block list fpc--->,params.slug", params.slug);
  useEffect(() => {
    setIsLoading(true);
    if (params.slug === undefined || params.slug === "undefined") {
      console.log("Get data from local storage");

      dashboardAPI(configRef.current)
        .then((res) => {
          if (res.data.status) {
            setIsLoading(false);
            setData(res.data.tehsilcountdata);
          } else {
            setData([]);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("something went wrong");
        });
    } else {
      getBlockDashBoardData(params.slug);
    }
  }, []);
  const getBlockDashBoardData = (id) => {
    setIsLoading(true);
    console.log("district id", id);
    const d = {
      fpc_id: id,
    };
    getBlockDashBoardDataListByFpc(d, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setData(res.data.tehsilcountdata);
          console.log("Block data", res.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("something went wrong");
      });
  };
  const handleApproved = (id, name, fpc_id) => {
    const d = {
      block_id: id,
      status: name,
      fps_name: fpc_id,
    };
    navigate("/dashboard", { state: d });
  };
  const handlePending = (id, name, fpc_id) => {
    const d = {
      block_id: id,
      status: name,
      fps_name: fpc_id,
    };
    navigate("/dashboard", { state: d });
  };
  const handleRejected = (id, name, fpc_id) => {
    const d = {
      block_id: id,
      status: name,
      fps_name: fpc_id,
    };
    navigate("/dashboard", { state: d });
  };
  return (
    <>
      <TopNav />
      <SideNavBar />

      <div className="content-wrapper">
        {userLoader ? (
          <div className="p-5 " style={{}}>
            <p style={{ color: "crimson", textAlign: "center" }}>
              {"Unauthorized"}
            </p>
            <p style={{ textAlign: "center" }}>
              You will be redirected to the home page in{" "}
              <b style={{ color: "crimson" }}>{timer} </b> seconds.
            </p>
          </div>
        ) : (
          ""
        )}
        {isLoading ? (
          <>
            {/* <SideNavBar /> */}
            <FullScreenLoader />
            {/* <div className="p-5" style={{}}>
              <p style={{ color: "crimson", textAlign: "center" }}>
                {"Unauthorized"}
              </p>
              <p style={{ textAlign: "center" }}>
                You will be redirected to the home page in{" "}
                <b style={{ color: "crimson" }}>{timer} </b> seconds.
              </p>
            </div> */}
          </>
        ) : (
          <>
            <div className="container-fluid">
              <div className="row mb-2">
                {/* <div className="col-sm-6">
            <h1 className="m-0">Officer Dashboard</h1>
          </div> */}
                {/* /.col */}
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-right">
                    {user.profile_data.user_type === "S" ? (
                      <li className="breadcrumb-item">
                        <Link
                          to={`${"/fpc-list"}`}
                          style={{ textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </li>
                    ) : user.profile_data.user_type === "V" ? (
                      <li className="breadcrumb-item">
                        <Link
                          to={`${"/block-list"}`}
                          style={{ textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </li>
                    ) : (
                      <li className="breadcrumb-item">
                        <Link
                          to={`${"/dashboard"}/${
                            user.profile_data.user_type === "E"
                              ? "admin"
                              : user.profile_data.user_type === "V"
                              ? "officer"
                              : user.profile_data.user_type === "O"
                              ? "field-officer"
                              : user.profile_data.user_type === "S"
                              ? "state-officer"
                              : ""
                          }`}
                          style={{ textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </li>
                    )}
                    <li className="breadcrumb-item active">block-list</li>
                  </ol>
                </div>
                {/* /.col */}
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <FaChartPie
                      style={{ with: 20, height: 20, marginRight: 5 }}
                    />
                    Block Details
                  </h4>
                </div>
              </div>
              {/* /.row */}
              <div className="row mb-3">
                {data.length > 0 ? (
                  <>
                    {data.map((item, index) => (
                      <div key={index} className="col-lg-3 col-6">
                        {/* <Link to={`/block-list/${item.block_id}`}> */}
                        <div
                          className="small-box"
                          style={{
                            borderRadius: 8,
                            backgroundColor: `#273764cf`,
                            padding: "10px",
                          }}
                        >
                          <div className="inner">
                            <p
                              style={{
                                fontSize: "18px",
                                color: "#fff",
                                fontWeight: "bold",
                              }}
                            >
                              {item.block_name}
                            </p>
                          </div>
                          <div
                            className="d-flex mb-1 block-status-approved"
                            style={{
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleApproved(
                                item.block_id,
                                item.approved_entries_label,
                                item.fpc_id
                              )
                            }
                          >
                            {/* <Link
                            //  
                            to={{pathname:"/dashboard",state:{{item.block_id,item.approved_entries_label}}}}
                            > */}
                            <p className="mb-0 text-white pl-1">
                              {item.approved_entries_label}
                            </p>
                            <p
                              className="mb-0  badge badge-success "
                              style={{ minWidth: "3rem" }}
                            >
                              {item.approved_entries_count}
                            </p>
                            {/* </Link> */}
                          </div>
                          <div
                            className="d-flex mb-1 block-status-pending"
                            style={{
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlePending(
                                item.block_id,
                                item.pending_entries_label,
                                item.fpc_id
                              )
                            }
                          >
                            <p className="mb-0 text-white pl-1">
                              {" "}
                              {item.pending_entries_label}
                            </p>
                            <p
                              className="mb-0 badge badge-warning"
                              style={{ minWidth: "3rem" }}
                            >
                              {item.pending_entries_count}
                            </p>
                          </div>
                          <div
                            className="d-flex mb-1 block-status-rejected"
                            style={{
                              justifyContent: "space-between",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleRejected(
                                item.block_id,
                                item.rejected_entries_label,
                                item.fpc_id
                              )
                            }
                          >
                            <p className="mb-0 text-white pl-1">
                              {item.rejected_entries_label}
                            </p>
                            <p
                              className="mb-0 badge badge-danger"
                              style={{ minWidth: "3rem" }}
                            >
                              {item.rejected_entries_count}
                            </p>
                          </div>
                          <div
                            className="d-flex mb-1"
                            style={{ justifyContent: "space-between" }}
                          >
                            <p className="mb-0 text-white pl-1">
                              {" "}
                              {item.total_entries_label}
                            </p>
                            <p
                              className="mb-0 badge badge-primary"
                              style={{ minWidth: "3rem" }}
                            >
                              {item.total_entries_count}
                            </p>
                          </div>
                        </div>
                        {/* </Link> */}
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    className="p-5 mb-5 mt-5"
                    style={{ textAlign: "center" }}
                  >
                    <h5 style={{ color: "crimson" }}>{"no records found"}</h5>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <Footer />
      </div>
    </>
  );
};

export default BlockList;
