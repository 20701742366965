import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import TopNav from "../../components/navbar/TopNav";
import SideNavBar from "../../components/sidebar/SideNavBar";
import { FiEdit } from "react-icons/fi";
import { FaChartPie } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { toast } from "react-toastify";
import { RiDeleteBin4Line } from "react-icons/ri";
import {
  addDistrictAPI,
  deleteDistrictAPI,
  getDistrictListAPI,
  updateDistrictAPI,
} from "../../api/ApiCall";
import Pagination from "../../components/common/pagination/Pagination";
import { regexALPHA } from "../../constant/Constants";
import $ from "jquery";
import "datatables.net";

export const DistrictScreen = () => {
  // Add the following state variables to your component's state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Set the number of items per page
  const [districtList, setDistrictList] = useState([]);
  const [stateList, setStateList] = useState([{ id: 10, name: "Bihar" }]);
  const [isLoading, setIsLoading] = useState(false);
  const [district, setDistrict] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [stateId, setStateId] = useState("10");
  const [isOpen, setIsOpen] = useState(false);
  const [timer, setTimer] = useState(5);
  const [userLoader, setUserLoader] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const configRef = useRef(null);
  const datatableRef = useRef(null);
  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setUserLoader(false);
      setUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    getDistrict();
  }, []);
  useEffect(() => {
    if (datatableRef.current) {
      $(datatableRef.current).DataTable().destroy();
      $(datatableRef.current).DataTable();
    }
  }, [districtList]);
  const getDistrict = () => {
    setIsLoading(true);
    getDistrictListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setDistrictList(res.data.data);
          if (datatableRef.current) {
            $(datatableRef.current).DataTable().destroy();
          }
          $(datatableRef.current).DataTable();
        } else {
          setIsLoading(false);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("500 internal server error");
      });
  };

  const openDelete = (item) => {
    setIsOpen(true);
    setData(item);
    setDistrict(item.district_name);
  };
  const closeDelete = () => {
    setIsOpen(false);
  };
  const handleClose = () => {
    setShow(false);
    setDistrict("");
    setData([]);
  };
  const handleDistrictDelete = (item) => {
    const data = {
      district_id: item.district_id,
    };
    setIsLoading(true);
    deleteDistrictAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          closeDelete();
          toast.success(res.data.message);
          getDistrict();
          // setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
  const handleShow = (item) => {
    setDistrict(item.district_name);
    setStateId(stateId);
    setData(item);
    setShow(true);
    console.log("+++++-->", item);
  };

  const handleDistrictSave = () => {
    setIsLoading(true);
    if (!regexALPHA.test(district)) {
      toast.error("please enter a valid district name");
      setIsLoading(false);
    } else if (district === "") {
      toast.error("please enter district name");
      setIsLoading(false);
    } else if (district === undefined || district === "undefined") {
      toast.error("please enter a valid district name");
      setIsLoading(false);
    } else if (district === null || district === "null") {
      toast.error("please enter a valid district name");
      setIsLoading(false);
    } else {
      console.error("district name", district);
      const data = {
        district_name: district,
        state_id: stateId,
      };
      addDistrictAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            getDistrict();
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error--->", err.response.data.message);
          toast.error(err.response.data.message);
        });
    }
  };

  const handleDistrictUpdate = (item) => {
    setIsLoading(true);
    if (!regexALPHA.test(district)) {
      toast.error("please enter valid district name");
      setIsLoading(false);
    } else if (district === "") {
      toast.error("please enter district name");
      setIsLoading(false);
    } else if (district === "undefined" || district === undefined) {
      toast.error("please enter a valid district name");
      setIsLoading(false);
    } else if (district === "null" || district === null) {
      toast.error("please enter a valid district name");
      setIsLoading(false);
    } else {
      const data = {
        district_id: item.district_id,
        district_name: district,
        state_id: item.state_id,
      };
      updateDistrictAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            getDistrict();
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  // Pagination
  const totalPages = Math.ceil(districtList.length / itemsPerPage); // Total number of pages
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <TopNav />
      <SideNavBar />
      {userLoader ? (
        <div className="p-5 " style={{}}>
          <p style={{ color: "crimson", textAlign: "center" }}>
            {"Unauthorized"}
          </p>
          <p style={{ textAlign: "center" }}>
            You will be redirected to the home page in{" "}
            <b style={{ color: "crimson" }}>{timer} </b> seconds.
          </p>
        </div>
      ) : (
        ""
      )}
      {isLoading ? (
        <>
          <FullScreenLoader />
          <div className="wrapper">
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-12">
                    <ol className="breadcrumb float-sm-right">
                      {user.profile_data.user_type === "S" ? (
                        <li className="breadcrumb-item">
                          <Link
                            to={`${"/fpc-list"}`}
                            style={{ textDecoration: "none" }}
                          >
                            Home
                          </Link>
                        </li>
                      ) : (
                        <li className="breadcrumb-item">
                          <Link
                            to={`${"/dashboard"}/${
                              user.profile_data.user_type === "E"
                                ? "admin"
                                : user.profile_data.user_type === "V"
                                ? "officer"
                                : user.profile_data.user_type === "O"
                                ? "field-officer"
                                : user.profile_data.user_type === "S"
                                ? "state-officer"
                                : ""
                            }`}
                            style={{ textDecoration: "none" }}
                          >
                            Home
                          </Link>
                        </li>
                      )}
                      <li className="breadcrumb-item active">district</li>
                    </ol>
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              <Button
                className="ml-3 mb-3"
                variant="primary"
                style={{ cursor: "pointer" }}
                onClick={handleShow}
              >
                Add District
              </Button>
              {/* /.content-header */}
              {/* Main content */}
              <section className="content">
                <div className="container-fluid">
                  {/* Dashboard card component */}
                  {/* Main row */}
                  <div className="row">
                    {/* Left col */}
                    <section className="col-lg-12 connectedSortable">
                      {/* Custom tabs (Charts with tabs)*/}
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title">
                            {/* <i className="fas fa-chart-pie mr-1" /> */}
                            <FaChartPie
                              style={{ with: 20, height: 20, marginRight: 5 }}
                            />
                            District List
                          </h4>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr role="row" className="bg-secondary">
                                  <th>S.No</th>
                                  <th>State</th>
                                  <th>District Name</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {districtList.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>

                                    <td value={item.state_id}>
                                      {item.state_name}
                                    </td>

                                    <td style={{ textTransform: "capitalize" }}>
                                      {item.district_name}
                                    </td>

                                    <td
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      {" "}
                                      <FiEdit
                                        className="edit-button mr-3"
                                        onClick={() => handleShow(item)}
                                        style={{
                                          with: 20,
                                          height: 20,
                                        }}
                                      />
                                      <RiDeleteBin4Line
                                        className="delete-button"
                                        onClick={() => openDelete(item)}
                                        style={{ with: 20, height: 20 }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* /.card-body */}
                      </div>
                      {/* /.card */}
                    </section>

                    {/* /.Left col */}
                  </div>
                  {/* /.row (main row) */}
                </div>
                {/* /.container-fluid */}
              </section>
              {/* /.content */}
            </div>
            <aside className="control-sidebar control-sidebar-dark"></aside>
          </div>
          {userLoader ? (
            ""
          ) : (
            <div className="wrapper">
              <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <Link
                            to="/dashboard"
                            style={{ textDecoration: "none" }}
                          >
                            Home
                          </Link>
                        </li>
                        <li className="breadcrumb-item active">Dashboard</li>
                      </ol>
                    </div>
                    {/* /.col */}
                  </div>
                  {/* /.row */}
                </div>
                <Button
                  className="ml-3 mb-3"
                  variant="primary"
                  style={{ cursor: "pointer" }}
                  onClick={handleShow}
                >
                  Add District
                </Button>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                  <div className="container-fluid">
                    {/* Dashboard card component */}
                    {/* Main row */}
                    <div className="row">
                      {/* Left col */}
                      <section className="col-lg-12 connectedSortable">
                        {/* Custom tabs (Charts with tabs)*/}
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              {/* <i className="fas fa-chart-pie mr-1" /> */}
                              <FaChartPie
                                style={{ with: 20, height: 20, marginRight: 5 }}
                              />
                              Recent Entries
                            </h4>
                          </div>
                          {/* /.card-header */}
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-bordered table-striped">
                                <thead>
                                  <tr role="row" className="bg-secondary">
                                    <th>S.No</th>
                                    <th>State</th>
                                    <th>District Name</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {districtList.map((item, index) => {
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td value={item.state_id}>
                                        {item.state_name}
                                      </td>
                                      <td
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {item.district_name}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        <FiEdit
                                          className="edit-button mr-3"
                                          onClick={() => handleShow(item)}
                                          style={{
                                            with: 20,
                                            height: 20,
                                          }}
                                        />
                                        <RiDeleteBin4Line
                                          className="delete-button"
                                          onClick={() => openDelete(item)}
                                          style={{ with: 20, height: 20 }}
                                        />
                                      </td>
                                    </tr>;
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* /.card-body */}
                        </div>
                        {/* /.card */}
                      </section>

                      {/* /.Left col */}
                    </div>
                    {/* /.row (main row) */}
                  </div>
                  {/* /.container-fluid */}
                </section>
                {/* /.content */}
              </div>
              <aside className="control-sidebar control-sidebar-dark"></aside>
            </div>
          )}
        </>
      ) : (
        <div className="wrapper">
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-right">
                    {user.profile_data.user_type === "S" ? (
                      <li className="breadcrumb-item">
                        <Link
                          to={`${"/fpc-list"}`}
                          style={{ textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </li>
                    ) : (
                      <li className="breadcrumb-item">
                        <Link
                          to={`${"/dashboard"}/${
                            user.profile_data.user_type === "E"
                              ? "admin"
                              : user.profile_data.user_type === "V"
                              ? "officer"
                              : user.profile_data.user_type === "O"
                              ? "field-officer"
                              : user.profile_data.user_type === "S"
                              ? "state-officer"
                              : ""
                          }`}
                          style={{ textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </li>
                    )}
                    <li className="breadcrumb-item active">district</li>
                  </ol>
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            <Button
              className="ml-3 mb-3"
              variant="primary"
              style={{ cursor: "pointer" }}
              onClick={handleShow}
            >
              Add District
            </Button>
            {/* /.content-header */}
            {/* Main content */}

            <section className="content">
              <div className="container-fluid">
                {/* Dashboard card component */}
                {/* Main row */}
                <div className="row">
                  {/* Left col */}
                  <section className="col-lg-12 connectedSortable">
                    {/* Custom tabs (Charts with tabs)*/}
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">
                          {/* <i className="fas fa-chart-pie mr-1" /> */}
                          <FaChartPie
                            style={{ with: 20, height: 20, marginRight: 5 }}
                          />
                          District List
                        </h4>
                      </div>
                      {/* /.card-header */}
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          ref={datatableRef}
                          className="table table-bordered table-striped"
                        >
                          <thead>
                            <tr role="row" className="bg-secondary">
                              <th>S.No</th>
                              <th>State</th>
                              <th>District Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {districtList
                              // .slice(
                              //   (currentPage - 1) * itemsPerPage,
                              //   currentPage * itemsPerPage
                              // )
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td value={item.state_id}>
                                    {item.state_name}
                                  </td>
                                  <td style={{ textTransform: "capitalize" }}>
                                    {item.district_name}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <FiEdit
                                      className="edit-button mr-3"
                                      onClick={() => handleShow(item)}
                                      style={{ width: 20, height: 20 }}
                                    />
                                    <RiDeleteBin4Line
                                      className="delete-button"
                                      onClick={() => openDelete(item)}
                                      style={{ width: 20, height: 20 }}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            {/* <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}

            {/* /.content */}
          </div>
          <aside className="control-sidebar control-sidebar-dark"></aside>
        </div>
      )}
      <Footer />
      <Modal show={show}>
        <Modal.Header>
          {!data.name ? (
            <Modal.Title>Add District</Modal.Title>
          ) : (
            <Modal.Title>Update District</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setStateId(e.target.value)}
                value={stateId}
              >
                {stateList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>District Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="district name"
                pattern="[A-Za-z]+"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!data.district_name ? (
            <Button variant="primary" onClick={() => handleDistrictSave(data)}>
              Save
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => handleDistrictUpdate(data)}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>
            <Modal.Title>Delete Category</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                style={{ borderColor: "crimson" }}
                type="text"
                placeholder="category name"
                value={district}
                autoFocus
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDelete}>
            Close
          </Button>

          <Button variant="danger" onClick={() => handleDistrictDelete(data)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
