import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import TopNav from "../../components/navbar/TopNav";
import SideNavBar from "../../components/sidebar/SideNavBar";
import { FiEdit } from "react-icons/fi";
import { FaChartPie } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Image, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { RiDeleteBin4Line } from "react-icons/ri";
import {
  addCommodityAPI,
  deleteCommodityAPI,
  getCategoryListAPI,
  getCommodityListAPI,
  getUnitListAPI,
  updateCommodityAPI,
} from "../../api/ApiCall";
import { BASE_URL_IMAGE, regexALPHA } from "../../constant/Constants";
import Pagination from "../../components/common/pagination/Pagination";
import $ from "jquery";
import "datatables.net";

export const CommodityScreen = () => {
  // Add the following state variables to your component's state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Set the number of items per page
  const [commodityList, setCommodityList] = useState([]);
  const [unit, setUnit] = useState("");
  const [category, setCategory] = useState("");
  const [unitId, setUnitId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [commodityName, setCommodityName] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [commodityImg, setCommodityImg] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  let formData = new FormData();
  const [timer, setTimer] = useState(5);
  const [userLoader, setUserLoader] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const configRef = useRef(null);
  const [showImg, setShowImg] = useState(false);
  const [commodityType, setCommodityType] = useState([
    { id: "sell", name: "Sell" },
    { id: "buy", name: "Buy" },
    { id: "buy,sell", name: "Both(Buy and Sell)" },
  ]);
  const [commodityTypeId, setCommodityTypeId] = useState("");
  const datatableRef = useRef(null);
  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setUserLoader(false);
      setUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    commodityDataList();
    unitData();
    categoryData();
  }, []);
  const commodityDataList = () => {
    setIsLoading(true);
    getCommodityListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setCommodityList(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };
  useEffect(() => {
    if (datatableRef.current) {
      $(datatableRef.current).DataTable().destroy();
      $(datatableRef.current).DataTable();
    }
  }, [commodityList]);
  const unitData = () => {
    setIsLoading(true);
    getUnitListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setUnitList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const categoryData = () => {
    setIsLoading(true);
    getCategoryListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setCategoryList(res.data.data);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const handleCategoryChange = (e) => {
    console.log("Coo", commodityName);
    setCategoryId(e.target.value);
  };
  const handleUniChange = (e) => {
    console.log("Cool", commodityName);
    setUnitId(e.target.value);
  };
  const openDelete = (item) => {
    setIsOpen(true);
    setData(item);
    setCommodityName(item.commodity_name);
  };
  const closeDelete = () => {
    setIsOpen(false);
  };
  const saveCommodityImage = (e) => {
    console.log("Cool-I", commodityName);
    setCommodityImg(e.target.files[0]);
  };
  const handleCommodityDelete = (item) => {
    const data = {
      commodity_id: item.commodity_id,
    };
    setIsLoading(true);
    deleteCommodityAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          closeDelete();
          toast.success(res.data.message);
          commodityDataList();
          // setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const handleShow = (item) => {
    console.log(item);
    setData(item);
    setShow(true);
    if (typeof item.commodity_id === "number") {
      setCommodityName(item.commodity_name);
      setCategoryId(item.category_id);
      setUnitId(item.uom_id);
      setCommodityTypeId(item.buy_or_sell);
      setShowImg(true);
    } else {
      setShowImg(false);
      setCommodityName("");
      setCategoryId("");
      setUnitId("");
      setCommodityTypeId("");
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleCommoditySave = () => {
    if (!commodityName) {
      // if (commodityName.length === 0) {
      toast.error("enter valid name");
      setIsLoading(false);
    } else if (commodityTypeId === "") {
      toast.error("select a commodity type");
    } else if (categoryId === "") {
      toast.error("select a category");
    } else if (unitId === "") {
      toast.error("select a unit");
    } else if (commodityImg === "") {
      toast.error("please select a image");
    } else {
      formData.append("commodity_name", commodityName);
      formData.append("buy_or_sell", commodityTypeId);
      formData.append("category_id", categoryId);
      formData.append("commodity_image", commodityImg);
      formData.append("uom_id", unitId);
      setIsLoading(true);
      console.log("Form Data", formData);
      // setIsLoading(false);
      addCommodityAPI(formData, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            commodityDataList(); //hit commodity list api
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  };
  const handleCommodityUpdate = (item) => {
    if (!commodityName) {
      toast.error("enter valid name");
      setIsLoading(false);
    } else if (commodityTypeId === "") {
      toast.error("select a commodity type");
    } else if (categoryId === "") {
      toast.error("select a category");
    } else if (unitId === "") {
      toast.error("select a unit");
    } else {
      const data = {
        commodity_id: item.commodity_id,
        commodity_name: item.commodity_name,
        category_id: item.category_id,
        uom_id: item.uom_id,
        commodity_image: "",
      };
      formData.append("commodity_name", commodityName);
      formData.append("commodity_id", item.commodity_id);
      formData.append("buy_or_sell", commodityTypeId);
      formData.append("category_id", categoryId);
      formData.append("commodity_image", commodityImg);
      formData.append("uom_id", unitId);
      setIsLoading(true);
      updateCommodityAPI(formData, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            commodityDataList();
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };
  // Pagination
  const totalPages = Math.ceil(commodityList.length / itemsPerPage); // Total number of pages
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      {isLoading ? <FullScreenLoader /> : ""}
      <TopNav />
      <SideNavBar />
      {userLoader ? (
        <div className="p-5" style={{}}>
          <p style={{ color: "crimson", textAlign: "center" }}>
            {"Unauthorized"}
          </p>
          <p style={{ textAlign: "center" }}>
            You will be redirected to the home page in{" "}
            <b style={{ color: "crimson" }}>{timer} </b> seconds.
          </p>
        </div>
      ) : (
        ""
      )}

      {isLoading ? (
        <>
          <FullScreenLoader />
          {userLoader ? (
            ""
          ) : (
            <div className="wrapper">
              <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <ol className="breadcrumb float-sm-right">
                        {user.profile_data.user_type === "S" ? (
                          <li className="breadcrumb-item">
                            <Link
                              to={`${"/fpc-list"}`}
                              style={{ textDecoration: "none" }}
                            >
                              Home
                            </Link>
                          </li>
                        ) : (
                          <li className="breadcrumb-item">
                            <Link
                              to={`${"/dashboard"}/${
                                user.profile_data.user_type === "E"
                                  ? "admin"
                                  : user.profile_data.user_type === "V"
                                  ? "officer"
                                  : user.profile_data.user_type === "O"
                                  ? "field-officer"
                                  : user.profile_data.user_type === "S"
                                  ? "state-officer"
                                  : ""
                              }`}
                              style={{ textDecoration: "none" }}
                            >
                              Home
                            </Link>
                          </li>
                        )}
                        <li className="breadcrumb-item active">commodity</li>
                      </ol>
                    </div>
                  </div>
                </div>
                <Button
                  className="ml-3 mb-3"
                  variant="primary"
                  style={{ float: "", cursor: "pointer" }}
                  onClick={handleShow}
                >
                  Add Commodity
                </Button>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                  <div className="container-fluid">
                    {/* Dashboard card component */}
                    {/* Main row */}
                    <div className="row">
                      {/* Left col */}
                      <section className="col-lg-12 connectedSortable">
                        {/* Custom tabs (Charts with tabs)*/}
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">
                              {/* <i className="fas fa-chart-pie mr-1" /> */}
                              <FaChartPie
                                style={{ with: 20, height: 20, marginRight: 5 }}
                              />
                              Recent Entries
                            </h4>
                          </div>
                          {/* /.card-header */}
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-bordered table-striped">
                                <thead>
                                  <tr role="row" className="bg-secondary">
                                    <th>S.No</th>
                                    <th>Commodity</th>
                                    <th>Category</th>
                                    <th>Unit</th>

                                    <th>Image</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {commodityList.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>

                                      <td>{item.name}</td>
                                      <td>
                                        <img
                                          src={item.image}
                                          style={{ width: 40, height: 40 }}
                                        />
                                      </td>

                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        <FiEdit
                                          className="edit-button mr-2"
                                          onClick={() => handleShow(item)}
                                          style={{ with: 30, height: 35 }}
                                        />
                                        <RiDeleteBin4Line
                                          className="delete-button"
                                          onClick={() => openDelete(item)}
                                          style={{ with: 30, height: 30 }}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          {/* /.card-body */}
                        </div>
                        {/* /.card */}
                      </section>

                      {/* /.Left col */}
                    </div>
                    {/* /.row (main row) */}
                  </div>
                  {/* /.container-fluid */}
                </section>
                {/* /.content */}
              </div>
              <aside className="control-sidebar control-sidebar-dark"></aside>
            </div>
          )}
        </>
      ) : (
        <div className="wrapper">
          <div className="content-wrapper">
            {/* Content Header (Page header) */}
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-12">
                  <ol className="breadcrumb float-sm-right">
                    {user.profile_data.user_type === "S" ? (
                      <li className="breadcrumb-item">
                        <Link
                          to={`${"/fpc-list"}`}
                          style={{ textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </li>
                    ) : (
                      <li className="breadcrumb-item">
                        <Link
                          to={`${"/dashboard"}/${
                            user.profile_data.user_type === "E"
                              ? "admin"
                              : user.profile_data.user_type === "V"
                              ? "officer"
                              : user.profile_data.user_type === "O"
                              ? "field-officer"
                              : user.profile_data.user_type === "S"
                              ? "state-officer"
                              : ""
                          }`}
                          style={{ textDecoration: "none" }}
                        >
                          Home
                        </Link>
                      </li>
                    )}
                    <li className="breadcrumb-item active">commodity</li>
                  </ol>
                </div>
              </div>
            </div>
            <Button
              className="ml-3 mb-3"
              variant="primary"
              style={{ float: "", cursor: "pointer" }}
              onClick={handleShow}
            >
              Add Commodity
            </Button>
            {/* /.content-header */}
            {/* Main content */}
            <section className="content">
              <div className="container-fluid">
                {/* Dashboard card component */}
                {/* Main row */}
                <div className="row">
                  {/* Left col */}
                  <section className="col-lg-12 connectedSortable">
                    {/* Custom tabs (Charts with tabs)*/}
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">
                          {/* <i className="fas fa-chart-pie mr-1" /> */}
                          <FaChartPie
                            style={{ with: 20, height: 20, marginRight: 5 }}
                          />
                          Commodity List
                        </h4>
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div className="table-responsive">
                          <table
                            ref={datatableRef}
                            className="table table-bordered table-striped"
                          >
                            <thead>
                              <tr role="row" className="bg-secondary">
                                <th>S.No</th>
                                <th>Commodity</th>
                                <th>Purpose Type</th>
                                <th>Category</th>
                                <th>Unit</th>
                                <th>Image</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {commodityList
                                // .slice(
                                //   (currentPage - 1) * itemsPerPage,
                                //   currentPage * itemsPerPage
                                // )
                                .map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      {index + 1}
                                      {/* {(currentPage - 1) * itemsPerPage +
                                        index +
                                        1} */}
                                    </td>

                                    <td value={item.commodity_id}>
                                      {item.commodity_name}
                                    </td>
                                    <td value={item.buy_or_sell}>
                                      {item.buy_or_sell}
                                    </td>
                                    <td value={item.category_id}>
                                      {item.category_name}
                                    </td>
                                    <td value={item.uom_id}>{item.uom_name}</td>
                                    <td>
                                      <img
                                        src={`${BASE_URL_IMAGE}${item.commodity_image}`}
                                        style={{ width: 40, height: 40 }}
                                      />
                                    </td>

                                    <td
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      {" "}
                                      <FiEdit
                                        className="edit-button mr-2"
                                        onClick={() => handleShow(item)}
                                        style={{ with: 30, height: 35 }}
                                      />
                                      <RiDeleteBin4Line
                                        className="delete-button"
                                        onClick={() => openDelete(item)}
                                        style={{ with: 30, height: 30 }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* /.card-body */}
                    </div>
                    {/* /.card */}
                  </section>

                  {/* /.Left col */}
                </div>
                {/* /.row (main row) */}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}
            {/* /.content */}
          </div>
          <aside className="control-sidebar control-sidebar-dark"></aside>
        </div>
      )}

      <Footer />
      <Modal show={show}>
        <Modal.Header>
          {!data.commodity_name ? (
            <Modal.Title>Add Commodity</Modal.Title>
          ) : (
            <Modal.Title>Update Commodity</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Commodity Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="commodity name"
                value={commodityName}
                onChange={(e) => setCommodityName(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Commodity Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setCommodityTypeId(e.target.value)}
                value={commodityTypeId}
              >
                <option value="">Select One</option>
                {commodityType.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Category Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleCategoryChange}
                // (e) => setCategory(e.target.value)}
                value={categoryId}
              >
                <option value="">Select One</option>
                {categoryList.map((item, index) => (
                  <option key={index} value={item.category_id}>
                    {item.category_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Unit Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleUniChange}
                // (e) => setUnit(e.target.name)}
                value={unitId}
              >
                <option value="">Select One</option>
                {unitList.map((item, index) => (
                  <option key={index} value={item.uom_id}>
                    {item.uom_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {showImg ? (
              <Form.Group
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Commodity Image</Form.Label>
                <Image
                  style={{ width: 350, height: 160 }}
                  src={`${BASE_URL_IMAGE}${data.commodity_image}`}
                  rounded
                />
              </Form.Group>
            ) : (
              ""
            )}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Change Commodity Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                placeholder="commodity image"
                // value={commodityName}
                // onChange={(e) => setCommodityName(e.target.value)}
                ref={inputRef}
                onChange={saveCommodityImage}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!data.commodity_name ? (
            <Button variant="primary" onClick={() => handleCommoditySave(data)}>
              Save
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => handleCommodityUpdate(data)}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>
            <Modal.Title>Delete Category</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                style={{ borderColor: "crimson" }}
                type="text"
                placeholder="category name"
                value={commodityName}
                autoFocus
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDelete}>
            Close
          </Button>

          <Button variant="danger" onClick={() => handleCommodityDelete(data)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
