import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FullScreenLoader from "../components/loader/FullScreenLoader";
import SideNavBar from "../components/sidebar/SideNavBar";
import Footer from "../components/footer/Footer";
import TopNav from "../components/navbar/TopNav";
import { FaChartPie } from "react-icons/fa";
import {
  dashboardAPI,
  stateFpcDashboardList,
  stateFpcDashboardYearWise,
  stateFpcDashboardYearWiseDetailedData,
} from "../api/ApiCall";
import { toast } from "react-toastify";
import axios from "axios";

const dd = {
  fpc_id: 1,
  fpc_name: "Saharsa",
  fpc_location: "None",
  fpc_address: "Saharsa",
  table: [
    {
      years: [
        // {
        //   year_label:"Financial Year",
        //   year: "2020-21",
        //   data: [
        //     {
        //       Category: "Output",
        //       CatData: [
        //         {

        //           commodity_name: "Maize 0 O",
        //           Qty: "12",
        //           amt: "12432",
        //           farmer: "12",
        //         },
        //         {
        //           commodity_name: "Maize seed 0 O",
        //           Qty: "12",
        //           amt: "12432",
        //           farmer: "12",
        //         },
        //       ],
        //     },
        //     {
        //       Category: "Input",
        //       CatData: [
        //         {
        //           commodity_name: "Maize Seed 1 I",
        //           Qty: "12",
        //           amt: "12432",
        //           farmer: "12",
        //         },
        //         {
        //           commodity_name: "Maize Seed 2 I",
        //           Qty: "12",
        //           amt: "12432",
        //           farmer: "12",
        //         },
        //         {
        //           commodity_name: "Tomato 3 I",
        //           Qty: "12",
        //           amt: "12432",
        //           farmer: "12",
        //         },
        //       ],
        //     },
        //   ],
        // },
        {
          year: "2021-22",
          data: [
            {
              Category: "Output",
              CatData: [
                {
                  commodity_name: "potato 1 O",
                  Qty: "12",
                  amt: "12432",
                  farmer: "12",
                },
                {
                  commodity_name: "potato 2 O",
                  Qty: "12",
                  amt: "12432",
                  farmer: "12",
                },
                {
                  commodity_name: "potato 3 O",
                  Qty: "12",
                  amt: "12432",
                  farmer: "12",
                },
                {
                  commodity_name: "Seed O",
                  Qty: "12",
                  amt: "12432",
                  farmer: "12",
                },
              ],
            },
            {
              Category: "Input",
              CatData: [
                {
                  commodity_name: "Potato I",
                  Qty: "12",
                  amt: "12432",
                  farmer: "12",
                },
                {
                  commodity_name: "Potato 2 I",
                  Qty: "12",
                  amt: "12432",
                  farmer: "12",
                },

                {
                  commodity_name: "Onion I",
                  Qty: "12",
                  amt: "12432",
                  farmer: "12",
                },
                {
                  commodity_name: "Carrot I",
                  Qty: "12",
                  amt: "12432",
                  farmer: "12",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const DistrictList = (props) => {
  const location = useLocation();
  const configRef = useRef(null);
  const navigate = useNavigate();
  const [timer, setTimer] = useState(5);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoader, setIsUserLoader] = useState(false);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [showTable, setShowTable] = useState(false);
  const [showFpcTable, setShowFpcTable] = useState(false);
  const [showFpcTableDetail, setShowFpcTableDetail] = useState(false);
  const [fpcTableData, setFpcTableData] = useState([]);
  const [fpcTableDataDetail, setFpcTableDataDetail] = useState([]);
  const date = new Date();
  var output = [];
  var input = [];

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  useEffect(() => {
    setIsUserLoader(true);
    if (user === null) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      // setData(user.fpc_count_data);
      setIsUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    setIsLoading(true);
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    const data = {
      user_type: "S",
    };
    dashboardAPI(configRef.current, data)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setData(res.data.tehsilcountdata);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleTable = (id) => {
    getDashboardData()
    setIsTableLoader(true);
    setShowFpcTable(false);
    setShowFpcTableDetail(false);
    const data = {
      fpc_list_id: id.fpc_list_id,
    };
    //hit api with auth token and id to get next table data
    stateFpcDashboardList(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsTableLoader(false);
          setShowTable(true);
          setTableData(res.data.fpc_list);
        } else {
          setIsTableLoader(false);
        }
      })
      .catch((err) => {
        setIsTableLoader(false);
      });
  };
  const handleFpcTable = (id) => {
    setShowFpcTableDetail(false);
    setIsLoading(true);
    const data = {
      fpc_id: id.fpc_id,
    };
    stateFpcDashboardYearWise(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setShowFpcTable(true);
          setFpcTableData(res.data.fpc_list);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleDetailFpc = (id) => {
    setIsLoading(true);
    setShowFpcTableDetail(true);
    const data = {
      // user_type: "S",
      fpc_id: fpcTableData.fpc_id,
      financial_year: id,
    };
    stateFpcDashboardYearWiseDetailedData(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setFpcTableDataDetail(res.data.fpc_list);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? <FullScreenLoader /> : ""}
      <TopNav />
      <SideNavBar />
      <div className="content-wrapper">
        {isUserLoader ? (
          <div className="p-5" style={{}}>
            <p style={{ color: "crimson", textAlign: "center" }}>
              {"Unauthorized"}
            </p>
            <p style={{ textAlign: "center" }}>
              You will be redirected to the home page in{" "}
              <b style={{ color: "crimson" }}>{timer} </b> seconds.
            </p>
          </div>
        ) : (
          ""
        )}

        <div className="wrapper">
          <div className="row mb-2">
            <div className="col-sm-12">
              <ol className="breadcrumb float-sm-right">
                {user.profile_data.user_type === "S" ? (
                  <li className="breadcrumb-item">
                    <Link
                      to={`${"/fpc-list"}`}
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </Link>
                  </li>
                ) : user.profile_data.user_type === "V" ? (
                  <li className="breadcrumb-item">
                    <Link
                      to={`${"/block-list"}`}
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </Link>
                  </li>
                ) : (
                  <li className="breadcrumb-item">
                    <Link
                      to={`${"/dashboard"}/${
                        user.profile_data.user_type === "E"
                          ? "admin"
                          : user.profile_data.user_type === "V"
                          ? "officer"
                          : user.profile_data.user_type === "O"
                          ? "field-officer"
                          : user.profile_data.user_type === "S"
                          ? "state-officer"
                          : ""
                      }`}
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </Link>
                  </li>
                )}
                <li className="breadcrumb-item active">fpc-list</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-lg-12 connectedSortable">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <FaChartPie
                        style={{ with: 20, height: 20, marginRight: 5 }}
                      />
                      FPC
                    </h4>
                  </div>
                </div>
              </section>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                {data ? (
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr
                        role="row"
                        className=""
                        style={{ fontSize: "14px", textAlign: "center" }}
                      >
                        <th className="thead-color-custome">{"S.No"}</th>

                        <th className="thead-color-custome">{"Total No. of FPC"}</th>
                        <th className="thead-color-custome">{"Total Transactions of Farmers"}</th>
                        <th className="thead-color-custome">{"Total Quantity (MT)"}</th>
                        <th className="thead-color-custome">{"Total Turnover (Lakh)"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {data.map((item,index)=>( */}

                      <tr
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          verticalAlign: "center",
                        }}
                      >
                        <td>{1}</td>
                        <td
                          onClick={() => handleTable(data)}
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                            cursor: "pointer",
                            // fontSize: "14px",
                          }}
                        >
                          {data.total_no_fpc}
                        </td>
                        <td>{data.total_transaction_formar_count}</td>
                        <td>{data.total_quantity}</td>
                        <td> {data.total_turnover_lakh}</td>
                      </tr>
                      {/* ))} */}
                    </tbody>
                  </table>
                ) : isLoading ? (
                  ""
                ) : (
                  <h6 style={{ color: "crimson", textAlign: "center" }}>
                    {"No records to display"}
                  </h6>
                )}
              </div>
            </div>
            {isTableLoader ? (
              <FullScreenLoader />
            ) : (
              <>
                {showTable ? (
                  <div className="card-body">
                    <div className="table-responsive">
                      {data ? (
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr role="row" className="">
                              <td colSpan={7}>
                                <h6 style={{ float: "right" }}>
                                  {"As on Date : "}
                                  {currentDate}
                                </h6>
                              </td>
                            </tr>
                            <tr
                              role="row"
                              className=""
                              style={{ fontSize: "14px", textAlign: "center", }}
                            >
                              <th className="thead-color-custome">{"Sr. No."}</th>
                              <th className="thead-color-custome">{"FPC Name"}</th>
                              <th className="thead-color-custome">{"Location"}</th>
                              <th className="thead-color-custome">{"Commodity"}</th>
                              <th className="thead-color-custome">{"Quantity (MT)"}</th>
                              <th className="thead-color-custome">{"Amount (Lakh)"}</th>
                              <th className="thead-color-custome">{"No. of Farmer"}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {data.map((item,index)=>( */}
                            {tableData.map((item, index) => (
                              <tr
                                style={{
                                  fontSize: "12px",
                                  textAlign: "center",
                                  verticalAlign: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleFpcTable(item)}
                              >
                                <td>{index + 1}</td>
                                <td>{item.fpc_name}</td>
                                <td>{item.location}</td>
                                <td style={{ textTransform: "capitalize" }}>
                                  {item.commodity_type}
                                </td>
                                <td>{item.quantity_in_MT}</td>
                                <td> {item.amount_in_lakh}</td>
                                <td>{item.No_of_farmer}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : isLoading ? (
                        ""
                      ) : (
                        <h6 style={{ color: "crimson", textAlign: "center" }}>
                          {"No records to display"}
                        </h6>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {showFpcTable ? (
              <div className="card-body">
                <div className="table-responsive">
                  {data ? (
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr
                          role="row"
                          className=""
                          style={{ textAlign: "center" }}
                        >
                          <td colSpan={5}>
                            {" "}
                            <label>{"FPC Name"}</label> :{" "}
                            {fpcTableData.fpc_name} <br></br>
                            <label>{"FPC Location"}</label> :{" "}
                            {fpcTableData.location}
                            <br></br>
                            {fpcTableData.address ? (
                              <>
                                <label>{"FPC Address"}</label>-{" "}
                                {fpcTableData.address}
                                <br></br>
                              </>
                            ) : (
                              ""
                            )}
                            {/* <label>{"FPC Address"}</label> : {fpcTableData.address}<br></br> */}
                          </td>
                        </tr>
                        <tr
                          role="row"
                          className=""
                          style={{ fontSize: "14px", textAlign: "center" }}
                        >
                          <th className="thead-color-custome">{"Financial Year"}</th>
                          <th className="thead-color-custome">{"Category"}</th>
                          <th className="thead-color-custome">{"Quantity (MT)"}</th>
                          <th className="thead-color-custome">{"Total Amount (Lakh)"}</th>
                          <th className="thead-color-custome">{"No. of Farmers"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fpcTableData.table[0].years.map((item, index) => (
                          <>
                            <tr
                              style={{
                                fontSize: "12px",
                                textAlign: "center",
                                verticalAlign: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleDetailFpc(item.financial_year);
                              }}
                            >
                              <td
                                style={{ verticalAlign: "middle" }}
                                rowSpan={2}
                              >
                                {item.financial_year}
                              </td>
                              <td>{item.data[0].Category}</td>
                              <td>{item.data[0].CatData[0].Quantity}</td>
                              <td>{item.data[0].CatData[0].amount}</td>
                              <td>{item.data[0].CatData[0].No_of_farmer}</td>
                            </tr>
                            <tr
                              style={{
                                fontSize: "12px",
                                textAlign: "center",
                                verticalAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <td>{item.data[1].Category}</td>
                              <td>{item.data[1].CatData[0].Quantity}</td>
                              <td>{item.data[1].CatData[0].amount}</td>
                              <td>{item.data[1].CatData[0].No_of_farmer}</td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  ) : isLoading ? (
                    ""
                  ) : (
                    <h6 style={{ color: "crimson", textAlign: "center" }}>
                      {"No records to display"}
                    </h6>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {showFpcTableDetail ? (
              <>
                <div className="card-body">
                  <div className="table-responsive">
                    {data ? (
                      <table className="table table-bordered table-striped">
                        <thead>
                          {isLoading ? (
                            ""
                          ) : (
                            <tr
                              // rowSpan={6}
                              role="row"
                              className=""
                              style={{ textAlign: "center" }}
                            >
                              <td>
                                <label>{"Financial Year"}</label> :{" "}
                                {
                                  fpcTableDataDetail.table[0].years[0]
                                    .financial_year
                                }
                                <br></br> <label>{"FPC Name"}</label> :{" "}
                                {fpcTableDataDetail.fpc_name}
                                <br></br>
                                <label>{"FPC Location"}</label> :{" "}
                                {fpcTableDataDetail.location}
                                <br></br>
                                {fpcTableDataDetail.address ? (
                                  <>
                                    <label>{"FPC Address"}</label> :{" "}
                                    {fpcTableDataDetail.fpc_address}
                                    <br></br>
                                  </>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          )}
                          {/* <tr
                            role="row"
                            className="bg-secondary"
                            style={{ fontSize: "14px", textAlign: "center" }}
                          >
                            <th>{"Financial Year"}</th>
                            <th>{"Category"}</th>
                            <th>{"Commodity Name"}</th>
                            <th>{"Quantity (MT)"}</th>
                            <th>{"Total Amount"}</th>
                            <th>{"No. of Farmers"}</th>
                          </tr> */}
                        </thead>
                        {isLoading ? (
                          ""
                        ) : (
                          <tbody style={{ width: "100%" }}>
                            {fpcTableDataDetail.table[0].years.map(
                              (item, index) => (
                                <tr
                                  key={index}
                                  style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                    verticalAlign: "center",
                                  }}
                                >
                                  <td style={{padding:'0px'}}>
                                    <table
                                      className=""
                                      style={{ width: "100%", padding: 0 }}
                                    >
                                    <tbody>
                                    <tr>
                                        <td className="" style={{fontWeight:'bold',backgroundColor:"#59636e2b",fontSize:'14px'}}>{"Commodity"}</td>{" "}
                                        <td style={{padding:'0px'}}>
                                          <table
                                            className="table "
                                            style={{
                                              width: "100%",
                                              marginBottom:'0px'
                                            }}
                                          >
                                            <thead
                                              
                                            >
                                              <tr>
                                                <th className="thead-color-custome" style={{ width: "40%",fontSize:'14px' }}>
                                                  {"Commodity Name"}
                                                </th>
                                                <th  className="thead-color-custome" style={{ width: "20%",fontSize:'14px' }}>
                                                  {"Quantity (MT)"}
                                                </th>
                                                <th  className="thead-color-custome" style={{ width: "20%",fontSize:'14px' }}>
                                                  {"Total Amount (Lakh)"}
                                                </th>
                                                <th  className="thead-color-custome" style={{ width: "20%",fontSize:'14px' }}>
                                                  {"No. of Farmers"}
                                                </th>
                                              </tr>
                                            </thead>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                      {item.data.map((data, index) => (
                                        <tr key={index} style={{ padding: 0 }}>
                                          <td
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            {data.Category}
                                          </td>
                                          <td style={{padding:'0px'}}>
                                            <table
                                              className="table table-striped"
                                              style={{ width: "100%",marginBottom:'0px' }}
                                            >
                                              {/* <thead>
                                        <tr>
                                          <th>{"Commodity Name"}</th>
                                          <th>{"Quantity (MT)"}</th>
                                          <th>{"Total Amount"}</th>
                                          <th>{"No. of Farmers"}</th>
                                        </tr>
                                      </thead> */}
                                              <tbody>
                                                {data.CatData.map(
                                                  (data, iindex) => (
                                                    <tr key={index}>
                                                      <td
                                                        style={{ width: "40%" }}
                                                      >
                                                        {data.commodity_name}
                                                      </td>
                                                      <td
                                                        style={{ width: "20%" }}
                                                      >
                                                        {data.Qty}
                                                      </td>
                                                      <td
                                                        style={{ width: "20%" }}
                                                      >
                                                        {data.amt}
                                                      </td>
                                                      <td
                                                        style={{ width: "20%" }}
                                                      >
                                                        {data.farmer}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      ))}
                                    </table>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        )}
                      </table>
                    ) : isLoading ? (
                      ""
                    ) : (
                      <h6 style={{ color: "crimson", textAlign: "center" }}>
                        {"No records to display"}
                      </h6>
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default DistrictList;
