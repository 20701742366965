import "./App.css";
import "./Adminlte.css";
import "react-toastify/dist/ReactToastify.css";
// import "./assets/js/AdminLTE.js";
// import "./assets/js/PushMenu.js";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./screens/Login";
import PageNotFound from "./screens/PageNotFound";
import Dashboard from "./screens/Dashboard";
import { DistrictScreen } from "./screens/forms/DistrictScreen";
import TehsilScreen from "./screens/forms/TehsilScreen";
import GrampanchayatScreen from "./screens/forms/GrampanchayatScreen";
import VillageScreen from "./screens/forms/VillageScreen";
import { CommodityScreen } from "./screens/forms/CommodityScreen";
import { UnitScreen } from "./screens/forms/UnitScreen";
import { UserScreen } from "./screens/forms/UserScreen";
import { CategoryScreen } from "./screens/forms/CategoryScreen";
import MultiStepForm from "./screens/forms/MultiStepForm";
import EntryForm from "./screens/forms/EntryForm";
import Kyc from "./screens/forms/Kyc";
import FpcScreen from "./screens/forms/FpcScreen";
import DistrictList from "./screens/DistrictList";
import BlockList from "./screens/BlockList";
import DashboardTwo from "./screens/DashboardTwo";
import { RejectScreen } from "./screens/forms/RejectScreen";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={"/login"} />}></Route>
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard/:slug" element={<Dashboard />} />
        <Route path="/admin/district" element={<DistrictScreen />} />
        <Route path="/admin/tehsil" element={<TehsilScreen />} />
        <Route path="/admin/grampanchayat" element={<GrampanchayatScreen />} />
        <Route path="/admin/village" element={<VillageScreen />} />
        <Route path="/admin/commodity" element={<CommodityScreen />} />
        <Route path="/admin/unit" element={<UnitScreen />} />
        <Route path="/admin/user" element={<UserScreen />} />
        <Route path="/admin/category" element={<CategoryScreen />} />
        <Route path="/admin/entry-form" element={<EntryForm />} />
        <Route path="/admin/kyc" element={<Kyc />} />
        <Route path="/admin/fpc" element={<FpcScreen />} />
        <Route path="/admin/reject" element={<RejectScreen />} />
        <Route path="/fpc-list" element={<DistrictList />} />
        <Route path="/block-list/:slug" element={<BlockList />} />
        <Route path="/block-list" element={<BlockList />} />
        {/* State Officer main Dashboard */}
        <Route path="/dashboard" element={<DashboardTwo />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
