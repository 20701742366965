import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopNav from "../../components/navbar/TopNav";
import SideNavBar from "../../components/sidebar/SideNavBar";
import Footer from "../../components/footer/Footer";
import { toast } from "react-toastify";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { Button, Form, Modal } from "react-bootstrap";
import { FaChartPie } from "react-icons/fa";
import {
  addFpcAPI,
  deleteFpcAPI,
  getDistrictListAPI,
  getFpcListAPI,
  updateFpcAPI,
} from "../../api/ApiCall";
import { regexALPHA } from "../../constant/Constants";

const FpcScreen = () => {
  const [districtList, setDistrictList] = useState([]);
  const [stateList, setStateList] = useState([{ id: 10, name: "Bihar" }]);
  const [stateId, setStateId] = useState(10);
  const [districtId, setDistrictId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  const [timer, setTimer] = useState(5);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [fpcName, setFpcName] = useState("");
  const [fpcDataList, setFpcDataList] = useState([]);
  const [shortName, setShortName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const configRef = useRef(null);

  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setUserLoader(false);
      setUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    fpcList();
    getDistrict();
  }, []);
  const getDistrict = () => {
    setIsLoading(true);
    getDistrictListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setDistrictList(res.data.data);
          // toast.success(res.data.message);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const fpcList = () => {
    setIsLoading(true);
    getFpcListAPI(configRef.current)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          setFpcDataList(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleShow = (item) => {
    console.log(item);
    setFpcName(item.name_organization);
    setShortName(item.short_name);
    setDistrictId(item.district_id);
    setData(item);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setFpcName("");
    setData([]);
  };
  const handleFpcSave = (item) => {
    if (districtId === "") {
      toast.error("please select district");
    } else if (districtId === undefined || districtId === "undefined") {
      toast.error("please select district");
    } else if (!regexALPHA.test(fpcName)) {
      toast.error("please enter a valid fpc name");
    } else if (fpcName === "undefined" || fpcName === undefined) {
      toast.error("please enter valid fpc name");
    } else if (fpcName === "null" || fpcName === null) {
      toast.error("please enter valid fpc name");
    } else if (!regexALPHA.test(shortName)) {
      toast.error("please enter a valid fpc short name");
    } else if (shortName === "null" || shortName === null) {
      toast.error("please enter valid fpc short name");
    } else if (shortName === "undefined" || shortName === undefined) {
      toast.error("please enter valid fpc short name");
    } else {
      setIsLoading(true);
      const data = {
        state: stateId,
        district: districtId,
        name_organization: fpcName,
        short_name: shortName,
      };
      addFpcAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            fpcList();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setIsLoading(false);
        });
    }
  };
  const handleFpcUpdate = (item) => {
    if (districtId === "") {
      toast.error("please select district");
    } else if (districtId === undefined || districtId === "undefined") {
      toast.error("please select district");
    } else if (!regexALPHA.test(fpcName)) {
      toast.error("please enter a valid fpc name");
    } else if (fpcName === "undefined" || fpcName === undefined) {
      toast.error("please enter valid fpc name");
    } else if (fpcName === "null" || fpcName === null) {
      toast.error("please enter valid fpc name");
    } else if (!regexALPHA.test(shortName)) {
      toast.error("please enter a valid fpc short name");
    } else if (shortName === "null" || shortName === null) {
      toast.error("please enter valid fpc short name");
    } else if (shortName === "undefined" || shortName === undefined) {
      toast.error("please enter valid fpc short name");
    } else {
      setIsLoading(true);
      const data = {
        fpc_id: item.fpc_id,
        state: stateId,
        district: districtId,
        name_organization: fpcName,
        short_name: shortName,
      };
      updateFpcAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            fpcList();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("500 internal error");
        });
    }
  };
  const openDelete = (item) => {
    setIsOpen(true);
    setData(item);
    setFpcName(item.name_organization);
  };
  const closeDelete = () => {
    setIsOpen(false);
  };
  const handleFpcDelete = (item) => {
    console.log(item);
    const data = {
      fpc_id: item.fpc_id,
    };
    setIsLoading(true);
    deleteFpcAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          closeDelete();
          toast.success(res.data.message);
          fpcList();
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);

        setIsLoading(false);
      });
  };
  const handleStateChange = (e) => {
    setStateId(e.target.value);
  };
  return (
    <div>
      {isLoading ? <FullScreenLoader /> : ""}
      <TopNav />
      <SideNavBar />
      {userLoader ? (
        <div className="p-5 " style={{}}>
          <p style={{ color: "crimson", textAlign: "center" }}>
            {"Unauthorized"}
          </p>
          <p style={{ textAlign: "center" }}>
            You will be redirected to the home page in{" "}
            <b style={{ color: "crimson" }}>{timer} </b> seconds.
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="wrapper">
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard" style={{ textDecoration: "none" }}>
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          <Button
            className="ml-3 mb-3"
            variant="primary"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            Add FPC
          </Button>
          {/* /.content-header */}
          {/* Main content */}

          <section className="content">
            <div className="container-fluid">
              {/* Dashboard card component */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <section className="col-lg-12 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {/* <i className="fas fa-chart-pie mr-1" /> */}
                        <FaChartPie
                          style={{ with: 20, height: 20, marginRight: 5 }}
                        />
                        FPC List
                      </h4>
                    </div>
                    {/* /.card-header */}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr role="row" className="bg-secondary">
                            <th>S.No</th>
                            <th>State</th>
                            <th>District</th>
                            <th>Producer Organization Name</th>
                            <th>Short Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {fpcDataList.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>

                              <td style={{ textTransform: "capitalize" }}>
                                {item.state_name}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {item.district_name}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {item.name_organization}
                              </td>
                              <td style={{ textTransform: "capitalize" }}>
                                {item.short_name}
                              </td>

                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                <FiEdit
                                  className="edit-button mr-3"
                                  onClick={() => handleShow(item)}
                                  style={{
                                    with: 20,
                                    height: 20,
                                  }}
                                />
                                <RiDeleteBin4Line
                                  className="delete-button"
                                  onClick={() => openDelete(item)}
                                  style={{ with: 20, height: 20 }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          {/* <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}

          {/* /.content */}
        </div>
        <aside className="control-sidebar control-sidebar-dark"></aside>
      </div>

      <Footer />
      <Modal show={show}>
        <Modal.Header>
          {!data.fpc_name ? (
            <Modal.Title>Add FPC</Modal.Title>
          ) : (
            <Modal.Title>Update FPC</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>State Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleStateChange}
                value={stateId}
              >
                {stateList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>District Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setDistrictId(e.target.value)}
                value={districtId}
              >
                <option value="">Select One</option>
                {districtList.map((item, index) => (
                  <option key={index} value={item.district_id}>
                    {item.district_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Producer Organization Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="producer organization name"
                value={fpcName}
                onChange={(e) => setFpcName(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Short Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="short name"
                value={shortName}
                onChange={(e) => setShortName(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!data.fpc_name ? (
            <Button variant="primary" onClick={() => handleFpcSave(data)}>
              Save
            </Button>
          ) : (
            <Button variant="primary" onClick={() => handleFpcUpdate(data)}>
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>FPC Name</Form.Label>
              <Form.Control
                style={{ borderColor: "crimson" }}
                type="text"
                placeholder="fpc name"
                value={fpcName}
                autoFocus
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDelete}>
            Close
          </Button>

          <Button variant="danger" onClick={() => handleFpcDelete(data)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FpcScreen;
