import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { dashboardAPI, loginAPI } from "../api/ApiCall";
import FullScreenLoader from "../components/loader/FullScreenLoader";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      username: email,
      password: password,
    };
    if (email === "null") {
      toast.error("Please enter valid username");
    } else if (email === "undefined") {
      toast.error("Please enter valid username");
    } else if (email === "") {
      toast.error("Please enter  username");
    } else if (password === "") {
      toast.error("Please enter password");
    } else {
      setIsLoading(true);
      loginAPI(data)
        .then((res) => {
          if (res.data.status === 200) {
            setIsLoading(false);
            var dat = JSON.stringify(res.data);
            localStorage.setItem("userDetails", dat);
            var fpc_count_data = JSON.stringify(res.data);
            localStorage.setItem("fpc_count_data", fpc_count_data);
            toast.success(res.data.message);
            if (res.data.profile_data.user_type === "S") {
              console.log("res --->S", res.data.token);
              const config = {
                headers: {
                  // "Content-Type": "application/json",
                  Authorization: "Bearer " + `${res.data.token}`,
                },
              };
              dashboardAPI(config).then((res) => {
                if (res.data.status === 200) {
                  var dat = JSON.stringify(res.data);
                  localStorage.setItem("dashboard", dat);
                  navigate(`/fpc-list`, { state: res.data });
                } else {
                }
              });
            } else if (res.data.profile_data.user_type === "V") {
              const config = {
                headers: {
                  // "Content-Type": "application/json",
                  Authorization: "Bearer " + `${res.data.token}`,
                },
              };
              dashboardAPI(config).then((res) => {
                if (res.data.status === 200) {
                  var dat = JSON.stringify(res.data);
                  localStorage.setItem("dashboard", dat);
                  navigate(`/block-list`, { state: res.data });
                } else {
                }
              });
            } else {
              navigate(
                `/dashboard/${
                  res.data.profile_data.user_type === "E"
                    ? "admin"
                    : res.data.profile_data.user_type === "O"
                    ? "field-officer"
                    : ""
                }`,
                { state: res.data }
              );
            }
          } else {
            setIsLoading(false);
            console.log("Error");
            toast.error("Something went wrong!");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error--->", err.response.data.message);
          toast.error(err.response.data.message);
        });
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <div className="login-page">
      {isLoading ? (
        <>
          <FullScreenLoader />
          <div className="login-box">
            {/* /.login-logo */}
            <div className="card card-outline card-primary">
              <div className="card-header text-center">
                <h1>
                  <b>JSPVAT</b>-MIS
                </h1>
              </div>
              <div className="card-body">
                <p className="login-box-msg">Sign in to start your session</p>
                <form action="../../index3.html" method="post">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <HiOutlineMail
                          style={{
                            fontSize: "24px",
                            color: "#672590",
                            textTransform: "capitalize",
                            backgroundColor: "",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          // onClick={() => handlePrint(props)}
                        />
                        {/* <span className="fas fa-user" /> */}
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <RiLockPasswordLine
                          style={{
                            fontSize: "24px",
                            color: "#672590",
                            textTransform: "capitalize",
                            backgroundColor: "",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          // onClick={() => handlePrint(props)}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="row d-flex"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {/* <div className="col-8">
                      <div className="icheck-primary">
                        <input type="checkbox" id="remember" />
                        <label htmlFor="remember">Remember Me</label>
                      </div>
                    </div> */}
                    {/* /.col */}
                    <div className="col-8">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        // onKeyPress={handleKeyPress}
                        className="btn btn-primary btn-block"
                      >
                        Sign In
                      </button>
                    </div>
                    {/* /.col */}
                  </div>
                </form>
                {/* /.social-auth-links */}
                {/* <p className="mb-1">
                  <label style={{}}>I forgot my password</label>
                </p> */}
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
        </>
      ) : (
        <div className="login-box">
          {/* /.login-logo */}
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <h1>
                <b>JSPVAT</b>-MIS
              </h1>
            </div>
            <div className="card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <HiOutlineMail
                        style={{
                          fontSize: "24px",
                          color: "#672590",
                          textTransform: "capitalize",
                          backgroundColor: "",
                          borderRadius: "5px",
                        }}
                        // onClick={() => handlePrint(props)}
                      />
                      {/* <span className="fas fa-user" /> */}
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <RiLockPasswordLine
                        style={{
                          fontSize: "24px",
                          color: "#672590",
                          textTransform: "capitalize",
                          backgroundColor: "",
                          borderRadius: "5px",
                        }}
                        // onClick={() => handlePrint(props)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="row d-flex"
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  {/* <div className="col-4">
                    <div className="icheck-primary">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">Remember Me</label>
                    </div>
                  </div> */}
                  {/* /.col */}
                  <div className="col-8 ">
                    <button
                      type="submit"
                      // onClick={handleSubmit}
                      onKeyPress={handleKeyPress}
                      className="btn btn-primary btn-block"
                    >
                      Sign In
                    </button>
                  </div>
                  {/* /.col */}
                </div>
              </form>
              {/* /.social-auth-links */}
              {/* <p className="mb-1">
                <label style={{}}>I forgot my password</label>
              </p> */}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </div>
      )}
    </div>
  );
};

export default Login;
