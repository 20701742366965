import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FaBars, FaRegComments } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiWechatLine } from "react-icons/ri";
import FullScreenLoader from "../loader/FullScreenLoader";
import { Link, useNavigate } from "react-router-dom";
const TopNav = () => {
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const handleLogout = () => {
    setIsLoading(true);
    setTimeout(() => {
      localStorage.removeItem("userDetails");
      setIsLoading(false);
      navigate("/");
    }, 2000);
    console.log("Logout-->");
  };
  return (
    <>
      {isLoading ? (
        <>
          <FullScreenLoader />
          <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" role="button">
                  {/* <i className="fas fa-bars" /> */}
                  <FaBars style={{ width: "1.3rem", height: "1.3rem" }} />
                </a>
              </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
              {/* Navbar Search */}
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  data-widget="navbar-search"
                  href="#"
                  role="button"
                >
                  <i className="fas fa-search" />
                </a>
                <div className="navbar-search-block">
                  <form className="form-inline">
                    <div className="input-group input-group-sm">
                      <input
                        className="form-control form-control-navbar"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-navbar" type="submit">
                          <i className="fas fa-search" />
                        </button>
                        <button
                          className="btn btn-navbar"
                          type="button"
                          data-widget="navbar-search"
                        >
                          <i className="fas fa-times" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </li> */}
              {/* Messages Dropdown Menu */}
              {/* <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                  <FaRegComments
                    style={{ width: "1.3rem", height: "1.3rem" }}
                  />
                  <span className="badge badge-danger navbar-badge">3</span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                  <a href="#" className="dropdown-item">
                    <div className="media">
                      <img
                        src="dist/img/user1-128x128.jpg"
                        alt="User Avatar"
                        className="img-size-50 mr-3 img-circle"
                      />
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          Brad Diesel
                          <span className="float-right text-sm text-danger">
                            <i className="fas fa-star" />
                          </span>
                        </h3>
                        <p className="text-sm">Call me whenever you can...</p>
                        <p className="text-sm text-muted">
                          <i className="far fa-clock mr-1" />4 Hours Ago
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="#" className="dropdown-item">
                    <div className="media">
                      <img
                        src="dist/img/user8-128x128.jpg"
                        alt="User Avatar"
                        className="img-size-50 img-circle mr-3"
                      />
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          John Pierce
                          <span className="float-right text-sm text-muted">
                            <i className="fas fa-star" />
                          </span>
                        </h3>
                        <p className="text-sm">I got your message bro</p>
                        <p className="text-sm text-muted">
                          <i className="far fa-clock mr-1" />4 Hours Ago
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="#" className="dropdown-item">
                    <div className="media">
                      <img
                        src="dist/img/user3-128x128.jpg"
                        alt="User Avatar"
                        className="img-size-50 img-circle mr-3"
                      />
                      <div className="media-body">
                        <h3 className="dropdown-item-title">
                          Nora Silvester
                          <span className="float-right text-sm text-warning">
                            <i className="fas fa-star" />
                          </span>
                        </h3>
                        <p className="text-sm">The subject goes here</p>
                        <p className="text-sm text-muted">
                          <i className="far fa-clock mr-1" />4 Hours Ago
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="#" className="dropdown-item dropdown-footer">
                    See All Messages
                  </a>
                </div>
              </li> */}
              {/* Notifications Dropdown Menu */}
              {/* <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                  <IoMdNotificationsOutline
                    style={{ width: "1.3rem", height: "1.3rem" }}
                  />
                  <span className="badge badge-warning navbar-badge">15</span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                  <span className="dropdown-item dropdown-header">
                    15 Notifications
                  </span>
                  <div className="dropdown-divider" />
                  <a href="#" className="dropdown-item">
                    <i className="fas fa-envelope mr-2" /> 4 new messages
                    <span className="float-right text-muted text-sm">
                      3 mins
                    </span>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="#" className="dropdown-item">
                    <i className="fas fa-users mr-2" /> 8 friend requests
                    <span className="float-right text-muted text-sm">
                      12 hours
                    </span>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="#" className="dropdown-item">
                    <i className="fas fa-file mr-2" /> 3 new reports
                    <span className="float-right text-muted text-sm">
                      2 days
                    </span>
                  </a>
                  <div className="dropdown-divider" />
                  <a href="#" className="dropdown-item dropdown-footer">
                    See All Notifications
                  </a>
                </div>
              </li> */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-widget="fullscreen"
                  href="#"
                  role="button"
                >
                  <i className="fas fa-expand-arrows-alt" />
                </a>
              </li>
            </ul>
            <Dropdown>
              <Dropdown.Toggle variant="Secondary" id="">
                <img
                  src="https://w7.pngwing.com/pngs/146/551/png-transparent-user-login-mobile-phones-password-user-miscellaneous-blue-text.png"
                  style={{ width: 20, height: 20, borderRadius: "50%" }}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>Action</Dropdown.Item>
                <Dropdown.Item>Another action</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </nav>
        </>
      ) : (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" data-widget="pushmenu" role="button">
                {/* <i className="fas fa-bars" /> */}
                <FaBars style={{ width: "1.3rem", height: "1.3rem" }} />
              </a>
            </li>
          </ul>
          {/* Right navbar links */}
          {user === null ? (
            ""
          ) : (
            <>
              <ul className="navbar-nav ml-auto">
                {/* Navbar Search */}
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    data-widget="navbar-search"
                    href="#"
                    role="button"
                  >
                    <i className="fas fa-search" />
                  </a>
                  <div className="navbar-search-block">
                    <form className="form-inline">
                      <div className="input-group input-group-sm">
                        <input
                          className="form-control form-control-navbar"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-navbar" type="submit">
                            <i className="fas fa-search" />
                          </button>
                          <button
                            className="btn btn-navbar"
                            type="button"
                            data-widget="navbar-search"
                          >
                            <i className="fas fa-times" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </li> */}
                {/* Messages Dropdown Menu */}
                {/* <li className="nav-item dropdown">
                  <a className="nav-link" data-toggle="dropdown" href="#">
                    <FaRegComments
                      style={{ width: "1.3rem", height: "1.3rem" }}
                    />
                    <span className="badge badge-danger navbar-badge">3</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a href="#" className="dropdown-item">
                      <div className="media">
                        <img
                          src="dist/img/user1-128x128.jpg"
                          alt="User Avatar"
                          className="img-size-50 mr-3 img-circle"
                        />
                        <div className="media-body">
                          <h3 className="dropdown-item-title">
                            Brad Diesel
                            <span className="float-right text-sm text-danger">
                              <i className="fas fa-star" />
                            </span>
                          </h3>
                          <p className="text-sm">Call me whenever you can...</p>
                          <p className="text-sm text-muted">
                            <i className="far fa-clock mr-1" />4 Hours Ago
                          </p>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                      <div className="media">
                        <img
                          src="dist/img/user8-128x128.jpg"
                          alt="User Avatar"
                          className="img-size-50 img-circle mr-3"
                        />
                        <div className="media-body">
                          <h3 className="dropdown-item-title">
                            John Pierce
                            <span className="float-right text-sm text-muted">
                              <i className="fas fa-star" />
                            </span>
                          </h3>
                          <p className="text-sm">I got your message bro</p>
                          <p className="text-sm text-muted">
                            <i className="far fa-clock mr-1" />4 Hours Ago
                          </p>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                      <div className="media">
                        <img
                          src="dist/img/user3-128x128.jpg"
                          alt="User Avatar"
                          className="img-size-50 img-circle mr-3"
                        />
                        <div className="media-body">
                          <h3 className="dropdown-item-title">
                            Nora Silvester
                            <span className="float-right text-sm text-warning">
                              <i className="fas fa-star" />
                            </span>
                          </h3>
                          <p className="text-sm">The subject goes here</p>
                          <p className="text-sm text-muted">
                            <i className="far fa-clock mr-1" />4 Hours Ago
                          </p>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item dropdown-footer">
                      See All Messages
                    </a>
                  </div>
                </li> */}
                {/* Notifications Dropdown Menu */}
                {/* <li className="nav-item dropdown">
                  <a className="nav-link" data-toggle="dropdown" href="#">
                    <IoMdNotificationsOutline
                      style={{ width: "1.3rem", height: "1.3rem" }}
                    />
                    <span className="badge badge-warning navbar-badge">15</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <span className="dropdown-item dropdown-header">
                      15 Notifications
                    </span>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                      <i className="fas fa-envelope mr-2" /> 4 new messages
                      <span className="float-right text-muted text-sm">
                        3 mins
                      </span>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                      <i className="fas fa-users mr-2" /> 8 friend requests
                      <span className="float-right text-muted text-sm">
                        12 hours
                      </span>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item">
                      <i className="fas fa-file mr-2" /> 3 new reports
                      <span className="float-right text-muted text-sm">
                        2 days
                      </span>
                    </a>
                    <div className="dropdown-divider" />
                    <a href="#" className="dropdown-item dropdown-footer">
                      See All Notifications
                    </a>
                  </div>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-widget="fullscreen"
                    href="#"
                    role="button"
                  >
                    <i className="fas fa-expand-arrows-alt" />
                  </a>
                </li>
              </ul>
              <Dropdown>
                <Dropdown.Toggle variant="Secondary" id="">
                  <img
                    src="https://w7.pngwing.com/pngs/146/551/png-transparent-user-login-mobile-phones-password-user-miscellaneous-blue-text.png"
                    style={{ width: 20, height: 20, borderRadius: "50%" }}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    {user.profile_data.user_type === "S" ? (
                      <Link to={`${"/fpc-list"}`}>Dashboard</Link>
                    ) : user.profile_data.user_type === "V" ? (
                      <Link to={`${"/block-list"}`}>Dashboard</Link>
                    ) : (
                      <Link
                        to={`${"/dashboard"}/${
                          user.profile_data.user_type === "E"
                            ? "admin"
                            : user.profile_data.user_type === "V"
                            ? "officer"
                            : user.profile_data.user_type === "O"
                            ? "field-officer"
                            : user.profile_data.user_type === "S"
                            ? "state-officer"
                            : ""
                        }`}
                      >
                        Dashboard
                      </Link>
                    )}
                  </Dropdown.Item>
                  {/* <Dropdown.Item>Another action</Dropdown.Item> */}
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </nav>
      )}
    </>
  );
};

export default TopNav;
