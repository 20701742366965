import React from "react";
import { Button } from "react-bootstrap";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const maxPageNumbers = 3; // Maximum number of page numbers to display
  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2)); // Calculate the starting page number
  const endPage = Math.min(startPage + maxPageNumbers - 1, totalPages); // Calculate the ending page number
  console.log("Total pages from pagination", totalPages);
  if (endPage - startPage < maxPageNumbers - 1) {
    startPage = Math.max(1, endPage - maxPageNumbers + 1);
  }

  // Generate an array of page numbers to display
  const pageNumbers = [];
  if (startPage > 1) {
    pageNumbers.push(1); // Add the first page number
    if (startPage > 2) {
      pageNumbers.push("..."); // Add ellipsis if there are more than 2 pages skipped
    }
  }
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }
  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pageNumbers.push("..."); // Add ellipsis if there are more than 2 pages remaining
    }
    pageNumbers.push(totalPages); // Add the last page number
  }

  return (
    <div
      className="pagination"
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {/* Previous page button */}
      <Button
        className="pagination__button m-2"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        Previous
      </Button>
      {/* Page numbers */}
      {pageNumbers.map((pageNumber, index) => (
        <Button
          key={index}
          className={`pagination__button ml-1 mr-1 mt-2 mb-2 ${
            pageNumber === currentPage ? "active" : ""
          }`}
          onClick={() => pageNumber !== "..." && onPageChange(pageNumber)}
        >
          {pageNumber}
        </Button>
      ))}
      {/* Next page button */}
      <Button
        className="pagination__button m-2"
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </Button>
    </div>
  );
};

export default Pagination;
