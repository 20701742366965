import axios from "axios";
import { BASE_URL } from "../constant/Constants";

//login
export const loginAPI = async (data) => {
  return await axios.post(`${BASE_URL}login`, data);
};
//login
export const dashboardAPI = async (config) => {
  return await axios.post(`${BASE_URL}dashboarduser`, null, config);
};

//add districtAdd
export const addDistrictAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}district`, data, config);
};
//add districtList
export const getDistrictListAPI = async (data) => {
  return await axios.post(`${BASE_URL}districtlist`, null, data);
};
//add districtUpdate
export const updateDistrictAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}updatedistrict`, data, config);
};
//add districtDelete
export const deleteDistrictAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}districtdelete`, data, config);
};
//add tehsilAdd
export const addTehsilAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}tehsil`, data, config);
};
//add tehsilList
export const getTehsilListAPI = async (config) => {
  return await axios.post(`${BASE_URL}tehsillist`, null, config);
};
//add tehsilUpdate
export const updateTehsilAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}tehsilupdate`, data, config);
};
//add tehsilDelete
export const deleteTehsilAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}tehsildelete`, data, config);
};

// addCategory
export const addCategoryAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}category`, data, config);
};
// getCategoryList
export const getCategoryListAPI = async (config) => {
  return await axios.post(`${BASE_URL}categorylist`, null, config);
};
// updateCategory
export const updateCategoryAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}categoryupdate`, data, config);
};
// deleteCategory
export const deleteCategoryAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}categorydelete`, data, config);
};
// kycList
export const getKycListAPI = async (config) => {
  return await axios.post(`${BASE_URL}kyclist`, null, config);
};
// kycAdd
export const addKycAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}kyc`, data, config);
};
// kycUpdate
export const updateKycAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}kycupdate`, data, config);
};
// kycDelete
export const deleteKycAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}kycdelete`, data, config);
};
// unitList
export const getUnitListAPI = async (config) => {
  return await axios.post(`${BASE_URL}uomlist`, null, config);
};
// unitAdd
export const addUnitAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}uom`, data, config);
};
// unitUpdate
export const updateUnitAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}uomupdate`, data, config);
};
// unitDelete
export const deleteUnitAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}uomdelete`, data, config);
};
// panchayatList
export const getPanchayatListAPI = async (config) => {
  return await axios.post(`${BASE_URL}panchayatList`, null, config);
};
// panchayatList
export const addPanchayatAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}panchayatcreate`, data, config);
};
// panchayatUpdate
export const updatePanchayatAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}updatepanchayat`, data, config);
};
// panchayatDelete
export const deletePanchayatAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}panchayatDelete`, data, config);
};

// get value of tehsil on change in district dropdown
export const getTehsilDropDownListAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}gettehsils`, data, config);
};
// get value of tehsil on change in district dropdown
export const getGramPanchayatDropDownListAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}getpanchayat`, data, config);
};
// get value of village on change in panchayat dropdown
export const getVillageDropDownListAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}getvillage`, data, config);
};

// addCommodity
export const addCommodityAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}createcommodity`, data, config);
};
// commodityList
export const getCommodityListAPI = async (config) => {
  return await axios.post(`${BASE_URL}commoditylist`, null, config);
};
// commodityUpdate
export const updateCommodityAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}updatecommodity`, data, config);
};
// commodityDelete
export const deleteCommodityAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}commoditydelete`, data, config);
};
// addVillage
export const addVillageAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}createvillage`, data, config);
};
// updateVillage
export const updateVillageAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}updatevillage`, data, config);
};
// deleteVillage
export const deleteVillageAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}deleteVillage`, data, config);
};
// listVillage
export const getVillageListAPI = async (config) => {
  return await axios.post(`${BASE_URL}villageList`, null, config);
};

// addFpc
export const addFpcAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}createFpc`, data, config);
};
// fpcList
export const getFpcListAPI = async (config) => {
  return await axios.post(`${BASE_URL}fpclist`, null, config);
};
// updateFpc
export const updateFpcAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}updatefpc`, data, config);
};
// deleteFpc
export const deleteFpcAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}deletefpc`, data, config);
};

// createUser
export const createUserAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}usercreate`, data, config);
};
// listUser
export const getUserListAPI = async (config) => {
  return await axios.post(`${BASE_URL}userlist`, null, config);
};

// listDashboardTableData
export const getDashboardListDataAPI = async (config) => {
  return await axios.post(`${BASE_URL}recententriesdashboard`, null, config);
};
// rejectDashboardTableData
export const rejectDashboardTableDataAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}cancelentrydashboard`, data, config);
};

// rapproveDashboardTableData
export const approveDashboardTableDataAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}confirmentrydashboard`, data, config);
};

//getBlock for state dashboard by district id

export const getBlockDashBoardDataListByDistrict = async (data, config) => {
  return await axios.post(`${BASE_URL}getcountdetailblock`, data, config);
};

//getBlock for state dashboard by fpc id

export const getBlockDashBoardDataListByFpc = async (data, config) => {
  return await axios.post(`${BASE_URL}block_count_by_fpc`, data, config);
};

//get table data by block ID and Status 'Pending' || 'Approved' || 'Rejected'

export const getTableDataByBlockIdAndStatus = async (data, config) => {
  return await axios.post(`${BASE_URL}get_formdata_by_block`, data, config);
};

// remark to add
export const createRemarkAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}remark`, data, config);
};
// remark to update
export const updateRemarkAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}remarkupdate`, data, config);
};
// remark to update
export const remarkListAPI = async (config) => {
  return await axios.post(`${BASE_URL}remarklist`, null, config);
};
// remark to update
export const deleteRemarkAPI = async (data, config) => {
  return await axios.post(`${BASE_URL}deleteremark`, data, config);
};

// TEST API FOR VILLAGE PAGINATION DATA
export const testVillagePagination = async (data) => {
  return await axios.post(`${BASE_URL}villageListpaginate`, data);
};
// TEST API FOR VILLAGE SEARCH DATA
export const testVillageSearch = async (data) => {
  return await axios.post(`${BASE_URL}searchListdata_village`, data);
};

// TEST API FOR PANCHAYAT PAGINATION DATA
export const testPanchayatPagination = async (data) => {
  return await axios.post(`${BASE_URL}panchayatListpaginate`, data);
};
// TEST API FOR PANCHAYAT SEARCH DATA
export const testPanchayatSearch = async (data) => {
  return await axios.post(`${BASE_URL}searchListdata_panchyat`, data);
};

// FPC List For State Dashboard  only
export const stateFpcDashboardList = async (data,config) => {
  return await axios.post(`${BASE_URL}fetchList`, data,config);
};
// FPC List Year Wise For State Dashboard  only
export const stateFpcDashboardYearWise = async (data,config) => {
  return await axios.post(`${BASE_URL}yearwisefpcist`, data,config);
};
// FPC List Year Wise Detailed Data For State Dashboard  only
export const stateFpcDashboardYearWiseDetailedData = async (data,config) => {
  return await axios.post(`${BASE_URL}yearwise_completedata`, data,config);
};

