import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  const user = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <div
      className=" p-4 m-2 d-flex"
      style={{
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div
        class="error__message message mt-2"
        style={{ width: "50%", float: "center" }}
      >
        <h1 class="message__title">Page Not Found</h1>
        <p class="message__text">
          We're sorry, the page you were looking for isn't found here. The link
          you followed may either be broken or no longer exists. Please try
          again, or take a look at our.
        </p>
      </div>
      <div class="error__nav e-nav">
        {user.profile_data.user_type === "S" ? (
          <Link to={`${"/fpc-list"}`} class="">
            <Button
              className="primary"
              sx={{ textTransform: "capitalize" }}
              // startIcon={<KeyboardBackspaceIcon />}
            >
              Home Page
            </Button>
          </Link>
        ) : user.profile_data.user_type === "V" ? (
          <Link to={`${"/block-list"}`} class="">
            <Button
              className="primary"
              sx={{ textTransform: "capitalize" }}
              // startIcon={<KeyboardBackspaceIcon />}
            >
              Home Page
            </Button>
          </Link>
        ) : (
          <Link
            to={`${"/dashboard"}/${
              user.profile_data.user_type === "E"
                ? "admin"
                : user.profile_data.user_type === "O"
                ? "field-officer"
                : ""
            }`}
            class=""
          >
            <Button
              className="primary"
              sx={{ textTransform: "capitalize" }}
              // startIcon={<KeyboardBackspaceIcon />}
            >
              Home Page
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default PageNotFound;
