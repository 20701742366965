import React from "react";
import { Button } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";

const EntryForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const submitForm = () => {
    console.log("Form", register);
    const formData = new FormData();

    // formData.append("files", register.picture[0]);
    // const d = { ...register, picture: register.picture[0].name };
    // formData.append("recipe", JSON.stringify(d));
    // return console.log(d);
    // return fetch("/api/recipes/create", {
    //   method: "POST",
    //   body: formData,
    // }).then((response) => {
    //   if (response.ok) {
    //     // Handle successful upload
    //   } else {
    //     // Handle error
    //   }
    // });
    // saveData(formData);
  };
  return (
    <div>
      EntryForm
      <h1>New recipe</h1>
      <form onSubmit={handleSubmit(submitForm)}>
        <input
          type="text"
          {...register("name", { required: "Name is required" })}
          id="name"
        />
        <input
          {...register("picture", {
            required: "Recipe picture is required",
          })}
          type="file"
          id="picture"
        />
        <Button variant="primary" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default EntryForm;
