import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer">
      <strong className="mr-1">
        Copyright © 2021-2023 <a href="#">JSPVAT-MIS</a>
      </strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 1.0.0
      </div>
    </footer>
  );
};

export default Footer;
