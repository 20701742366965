import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import TopNav from "../../components/navbar/TopNav";
import SideNavBar from "../../components/sidebar/SideNavBar";
import { FiEdit, FiEye } from "react-icons/fi";
import { FaChartPie } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { toast } from "react-toastify";
import { RiDeleteBin4Line } from "react-icons/ri";
import {
  addVillageAPI,
  deleteVillageAPI,
  getDistrictListAPI,
  getGramPanchayatDropDownListAPI,
  getPanchayatListAPI,
  getTehsilDropDownListAPI,
  getVillageListAPI,
  testVillagePagination,
  testVillageSearch,
  updateVillageAPI,
} from "../../api/ApiCall";
import Pagination from "../../components/common/pagination/Pagination";
import { regexALPHA } from "../../constant/Constants";
import $ from "jquery";
import "datatables.net";

const villageData = [
  {
    tId: 1,
    tName: "Tehsil-1",
    dId: 1,
    dName: "Ara",
    sId: 1,
    sName: "Bihar",
    gId: 1,
    gName: "Gram-1",
    vId: 1,
    vName: "Village-1",
  },
  {
    tId: 2,
    tName: "Tehsil-2",
    dId: 2,
    dName: "Siwan",
    sId: 1,
    sName: "Bihar",
    gId: 2,
    gName: "Gram-2",
    vId: 2,
    vName: "Village-2",
  },
  {
    tId: 3,
    tName: "Tehsil-3",
    dId: 3,
    dName: "Patna",
    sId: 1,
    sName: "Bihar",
    gId: 3,
    gName: "Gram-3",
    vId: 3,
    vName: "Village-3",
  },
];
const VillageScreen = () => {
  // Add the following state variables to your component's state
  const [currentPage, setCurrentPage] = useState(1);
  const [tPage, setTPage] = useState("");

  const [itemsPerPage] = useState(25); // Set the number of items per page
  const [villageList, setVillageList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [stateList, setStateList] = useState([{ id: 10, name: "Bihar" }]);
  const [tehsilList, setTehsilList] = useState([]);
  const [gramList, setGramList] = useState([]);
  const [show, setShow] = useState(false);
  const [villageId, setVillageId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [stateId, setStateId] = useState(10);
  const [tehsilId, setTehsilId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [villageName, setVillageName] = useState("");
  const [gramId, setGramId] = useState("1");
  const [timer, setTimer] = useState(5);
  const [userLoader, setUserLoader] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const configRef = useRef(null);
  const datatableRef = useRef(null);
  const [filteredItem, setFilteredItem] = useState(null);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setUserLoader(false);
      setUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    getDistrict();
    villageDataList();
    panchayatDataList();
  }, []);

  const villageDataList = () => {
    setIsLoading(true);
    const p = {
      page: currentPage,
      village_search: searchText,
    };
    testVillageSearch(p)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setTPage(res.data.total_pages);
          setVillageList(res.data.data);
          console.log("Data===>", res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
    // getVillageListAPI(configRef.current)
    //   .then((res) => {
    //     if (res.data.status === 200) {
    //       setIsLoading(false);
    //       setVillageList(res.data.data);
    //       // if (datatableRef.current) {
    //       //   $(datatableRef.current).DataTable().destroy();
    //       // }
    //       // $(datatableRef.current).DataTable();
    //     } else {
    //       setIsLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     toast.error(err);
    //   });
  };
  useEffect(() => {
    if (datatableRef.current) {
      $(datatableRef.current).DataTable().destroy();
      $(datatableRef.current).DataTable();
    }
  }, [villageList]);
  const panchayatDataList = () => {
    setIsLoading(true);
    getPanchayatListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setGramList(res.data.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error("500 server error");
        setIsLoading(false);
      });
  };
  const getDistrict = () => {
    setIsLoading(true);
    getDistrictListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setDistrictList(res.data.data);
          // toast.success(res.data.message);
        } else {
          setIsLoading(false);
          // toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const handleShow = (item) => {
    console.log(item);
    setShow(true);
    setData(item);
    if (item.state_id === 10) {
      console.log("Update");
      setStateId(item.state_id);
      setDistrictId(item.district_id);
      setTehsilId(item.tehsil_id);
      setGramId(item.panchayat_id);
      setVillageName(item.village_name);
      setVillageId(item.village_id);
      handleDistrictChange(item.district_id);
      handleTehsilChange(item.tehsil_id);
    } else {
      console.log("Add");
      setStateId(10);
      setDistrictId("");
      setTehsilId("");
      setGramId("");
    }
  };
  const openDelete = (item) => {
    setIsOpen(true);
    setData(item);
    setVillageName(item.village_name);
  };
  const handleVillageSave = (item) => {
    if (districtId === "") {
      toast.error("please select district");
    } else if (tehsilId === "") {
      toast.error("please select tehsil");
    } else if (gramId === "") {
      toast.error("please select gram panchayat");
    } else if (!regexALPHA.test(villageName)) {
      toast.error("please enter valid  village name");
      setIsLoading(false);
    } else if (villageName === "") {
      toast.error("please enter village name");
      setIsLoading(false);
    } else if (villageName === "undefined" || villageName === undefined) {
      toast.error("please enter a valid village name");
      setIsLoading(false);
    } else if (villageName === "null" || villageName === null) {
      toast.error("please enter a valid village name");
      setIsLoading(false);
    } else {
      const data = {
        state: stateId,
        district: districtId,
        block_id: tehsilId,
        panchayat_id: gramId,
        village_name: villageName,
      };
      setIsLoading(true);
      addVillageAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            villageDataList(); //hit villageList api
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setIsLoading(false);
        });
    }
  };
  const handleVillageUpdate = async (item) => {
    if (districtId === "") {
      toast.error("please select district");
    } else if (tehsilId === "") {
      toast.error("please select tehsil");
    } else if (gramId === "") {
      toast.error("please select gram panchayat");
    } else if (!regexALPHA.test(villageName)) {
      toast.error("please enter valid  village name");
      setIsLoading(false);
    } else if (villageName === "") {
      toast.error("please enter village name");
      setIsLoading(false);
    } else if (villageName === "undefined" || villageName === undefined) {
      toast.error("please enter a valid village name");
      setIsLoading(false);
    } else if (villageName === "null" || villageName === null) {
      toast.error("please enter a valid village name");
      setIsLoading(false);
    } else {
      const data = {
        state_id: item.state_id,
        district_id: districtId,
        block_id: tehsilId,
        panchayat_id: gramId,
        village_id: villageId,
        village_name: villageName,
      };
      setIsLoading(true);
      await updateVillageAPI(data, configRef.current)
        .then((res) => {
          if (res.data.message) {
            handleClose();
            toast.success(res.data.message);
            villageDataList();
            // setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setIsLoading(false);
        });
    }
  };
  const handleVillageDelete = (item) => {
    const data = {
      village_id: item.village_id,
    };
    setIsLoading(true);
    deleteVillageAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          closeDelete();
          toast.success(res.data.message);
          villageDataList();
          // setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const closeDelete = () => {
    setIsOpen(false);
    setVillageName("");
  };
  const handleClose = () => {
    setShow(false);
    setVillageName("");
    setDistrictId("");
    setTehsilId("");
    setGramId("");
    setVillageName("");
    setVillageId("");
  };
  const handleStateChange = (e) => {
    setStateId(e.target.value);
    console.log("State", e.target.value);
  };
  const handleDistrictChange = async (e) => {
    setTehsilList([]);
    setGramList([]);
    let sel = "";
    if (typeof e === "number") {
      sel = e;
    } else {
      sel = e.target.value;
      setTehsilId("");
      setGramId("");
    }
    setDistrictId(sel);
    const data = {
      district_id: sel,
    };
    await getTehsilDropDownListAPI(data, configRef.current)
      .then((res) => {
        setIsLoading(true);
        if (res.data.status === 200) {
          setIsLoading(false);
          setTehsilList(res.data.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  };
  const handleTehsilChange = async (e) => {
    setGramList([]);
    let sel = "";
    if (typeof e === "number") {
      sel = e;
    } else {
      sel = e.target.value;
      setGramId("");
    }
    setTehsilId(sel);
    const data = {
      tehsil_id: sel,
    };
    await getGramPanchayatDropDownListAPI(data, configRef.current)
      .then((res) => {
        setIsLoading(true);
        if (res.data.status === 200) {
          setIsLoading(false);
          setGramList(res.data.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  };
  // Pagination
  // const totalPages = Math.ceil(villageList.length / itemsPerPage); // Total number of pages
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    test(pageNumber, searchText);
  };
  const test = (a, searchText) => {
    setIsLoading(true);
    const p = {
      page: a,
      village_search: searchText,
    };
    testVillageSearch(p)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setTPage(res.data.total_pages);
          setCurrentPage(res.data.current_page);
          setVillageList(res.data.data);
          console.log("Data===>", res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const handleSearch = (e) => {
    setIsLoading(true);
    // const i = e.target.value;
    // setSearchText(e.target.value);
    console.log("Filter text", searchText);
    const d = {
      page: currentPage > 1 ? 1 : currentPage,
      village_search: searchText,
    };
    testVillageSearch(d)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setVillageList(res.data.data);
          setTPage(res.data.total_pages);
          setCurrentPage(res.data.current_page);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
          setFilteredItem(null);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleCloseSearch = () => {
    setIsLoading(true);
    let a = "";
    setSearchText(a);
    const d = {
      page: "",
      village_search: "",
    };
    testVillageSearch(d)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setVillageList(res.data.data);
          setTPage(res.data.total_pages);
          setCurrentPage(res.data.current_page);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
          setFilteredItem(null);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleChange = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      console.log("If", e.target.value);
      handleCloseSearch();
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  console.log("Total pages", tPage);
  return (
    <div>
      {isLoading ? <FullScreenLoader /> : ""}
      <TopNav />
      <SideNavBar />
      {userLoader ? (
        <div className="p-5" style={{}}>
          <p style={{ color: "crimson", textAlign: "center" }}>
            {"Unauthorized"}
          </p>
          <p style={{ textAlign: "center" }}>
            You will be redirected to the home page in{" "}
            <b style={{ color: "crimson" }}>{timer} </b> seconds.
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="wrapper">
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <ol className="breadcrumb float-sm-right">
                  {user.profile_data.user_type === "S" ? (
                    <li className="breadcrumb-item">
                      <Link
                        to={`${"/fpc-list"}`}
                        style={{ textDecoration: "none" }}
                      >
                        Home
                      </Link>
                    </li>
                  ) : (
                    <li className="breadcrumb-item">
                      <Link
                        to={`${"/dashboard"}/${
                          user.profile_data.user_type === "E"
                            ? "admin"
                            : user.profile_data.user_type === "V"
                            ? "officer"
                            : user.profile_data.user_type === "O"
                            ? "field-officer"
                            : user.profile_data.user_type === "S"
                            ? "state-officer"
                            : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Home
                      </Link>
                    </li>
                  )}
                  <li className="breadcrumb-item active">village</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.content-header */}
          <Button
            className="ml-3 mb-3"
            variant="primary"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            Add Village
          </Button>
          <section className="content">
            <div className="container-fluid">
              {/* Dashboard card component */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <section className="col-lg-12 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div
                      className="d-flex p-3"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FaChartPie
                          style={{ with: 20, height: 20, marginRight: 5 }}
                        />
                        <span style={{ fontSize: "1.1rem", fontWeight: 400 }}>
                          Village List
                        </span>
                      </div>
                      <div
                        style={{ float: "right" }}
                        id="DataTables_Table_2_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          Search:
                          <input
                            type="search"
                            className="p-1"
                            style={{
                              border: "1px solid #bfbfbf",
                              marginLeft: "2px",
                              borderRadius: "3px",
                            }}
                            value={searchText}
                            onChange={handleChange}
                            placeholder=""
                            onKeyDown={handleKeyDown}
                            aria-controls="DataTables_Table_2"
                          />
                        </label>
                      </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="table-responsive">
                        <table
                          // ref={datatableRef}
                          className="table table-bordered table-striped"
                        >
                          <thead>
                            <tr role="row" className="bg-secondary">
                              <th>S.No</th>
                              <th>State</th>
                              <th>District</th>
                              <th>Tehsil</th>
                              <th>Grampanchayat</th>
                              <th>Village</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {villageList
                              // .slice(
                              //   (currentPage - 1) * itemsPerPage,
                              //   currentPage * itemsPerPage
                              // )
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    {/* {index + 1} */}
                                    {(currentPage - 1) * itemsPerPage +
                                      index +
                                      1}
                                  </td>
                                  <td value={item.state_id}>
                                    {item.state_name}
                                  </td>
                                  <td value={item.district_id}>
                                    {item.district_name}
                                  </td>
                                  <td value={item.tehsil_id}>
                                    {item.tehsil_name}
                                  </td>
                                  <td value={item.panchayat_id}>
                                    {item.panchayat_name}
                                  </td>
                                  <td value={item.village_id}>
                                    {item.village_name}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <FiEdit
                                      className="edit-button mr-3"
                                      onClick={() => handleShow(item)}
                                      style={{
                                        with: 20,
                                        height: 20,
                                      }}
                                    />
                                    <RiDeleteBin4Line
                                      className="delete-button"
                                      onClick={() => openDelete(item)}
                                      style={{ with: 20, height: 20 }}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </section>

                {/* /.Left col */}
              </div>
              {/* /.row (main row) */}
            </div>
            {/* /.container-fluid */}
          </section>
          <Pagination
            currentPage={currentPage}
            totalPages={tPage}
            onPageChange={handlePageChange}
          />
          {/* Main content */}

          {/* /.content */}
        </div>
        <aside className="control-sidebar control-sidebar-dark"></aside>
      </div>
      <Footer />
      <Modal show={show}>
        <Modal.Header>
          {!data.village_name ? (
            <Modal.Title>Add Village</Modal.Title>
          ) : (
            <Modal.Title>Update Village</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>State Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleStateChange}
                value={stateId}
              >
                {stateList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>District Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleDistrictChange}
                value={districtId}
              >
                <option value="">Select One</option>
                {districtList.map((item, index) => (
                  <option key={index} value={item.district_id}>
                    {item.district_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Tehsil Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleTehsilChange}
                value={tehsilId}
              >
                <option value="">Select One</option>
                {tehsilList.map((item, index) => (
                  <option key={index} value={item.tehsil_id}>
                    {item.tehsil_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Grampanchayat Name</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setGramId(e.target.value)}
                value={gramId}
              >
                {" "}
                <option value="">Select One</option>
                {gramList.map((item, index) => (
                  <option key={index} value={item.panchayat_id}>
                    {item.panchayat_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label>Village Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="village name"
                value={villageName}
                onChange={(e) => setVillageName(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!data.village_name ? (
            <Button variant="primary" onClick={() => handleVillageSave(data)}>
              Save
            </Button>
          ) : (
            <Button variant="primary" onClick={() => handleVillageUpdate(data)}>
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>
            <Modal.Title>Delete Village</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Village Name</Form.Label>
              <Form.Control
                style={{ borderColor: "crimson" }}
                type="text"
                placeholder="village name"
                value={villageName}
                autoFocus
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDelete}>
            Close
          </Button>

          <Button variant="danger" onClick={() => handleVillageDelete(data)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VillageScreen;
