import React from "react";

const IconDisplay = ({ icon, width, height, color }) => {
  const IconComponent = icon; // Get the component based on the icon prop
  return (
    <IconComponent style={{ width: width, height: height, color: color }} />
  );
};

export default IconDisplay;
