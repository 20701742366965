import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import TopNav from "../../components/navbar/TopNav";
import SideNavBar from "../../components/sidebar/SideNavBar";
import { FiEdit } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { FaChartPie } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RiDeleteBin4Line } from "react-icons/ri";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import { Button, Form, Image, Modal } from "react-bootstrap";
import {
  createUserAPI,
  getFpcListAPI,
  getUserListAPI,
} from "../../api/ApiCall";
import {
  BASE_URL_IMAGE,
  regexALPHA,
  regexALPHAONLY,
  regexEmail,
  regexMobile,
  regexPassword,
} from "../../constant/Constants";
import Pagination from "../../components/common/pagination/Pagination";

export const UserScreen = () => {
  // Add the following state variables to your component's state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Set the number of items per page
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  const [timer, setTimer] = useState(5);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [fpcShow, setFpcShow] = useState(false);
  const [userName, setUserName] = useState("");
  const [user_name,setUser_Name] =useState("")
  const [designationId, setDesignationId] = useState("");
  const [designationList, setDesignationList] = useState([
    { id: "S", name: "State Login" },
    { id: "O", name: "Field Officers" },
    { id: "V", name: "FPC login" },
  ]);
  const [activeList, setActiveList] = useState([
    { id: 1, name: "Active" },
    { id: 0, name: "Inactive" },
  ]);
  const [fpcDataList, setFpcDataList] = useState([]);
  const [fpcId, setFpcId] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("");
  const [active, setActive] = useState("");
  const [statusId, setStatusId] = useState("");
  const [userImage, setUserImage] = useState("");
  const [showImg, setShowImg] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  let formData = new FormData();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const configRef = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setUserLoader(false);
      setUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    fpcData();
    getUser();
  }, []);
  const fpcData = () => {
    getFpcListAPI(configRef.current)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          setFpcDataList(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getUser = () => {
    setIsLoading(true);
    getUserListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setUserList(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("internal server error");
      });
  };
  const handleShow = (item) => {
    setUserName(item.name_organization);
    setDesignationId(item.user_type);
    setFpcId(item.fpc_id);
    setUserName(item.employee_name);
    setEmail(item.email);
    setPassword(item.password);
    setMobile(item.mobile_no);
    setAddress(item.address);
    setStatusId(item.active_flag);
    setUserImage(item.user_profile_pic);
    setUser_Name(item.user_name);
    setData(item);
    setShow(true);
    if (item.user_profile_pic) {
      setShowImg(true);
    } else {
      setShowImg(false);
    }
    if(item.fpc_id){
setFpcShow(true)
    }else{
setFpcShow(false)
    }
  };
  const handleClose = () => {
    setShow(false);
    setUserName("");
    setData([]);
    setFpcShow(false)
  };
  const handleUserSave = () => {
    const data = {
      userType: designationId,
      fps_id: fpcId,
      employee_name: userName,
      user_name:user_name,
      email: email,
      mobile_no: mobile,
      password: password,
      address_line1: address,
      status: statusId,
      user_profile_pic: "",
    };
    if (
      designationId === "" ||
      designationId === undefined ||
      designationId === "undefined"
    ) {
      toast.error("select designation");
    } else if (userName === "undefined") {
      toast.error("enter valid name");
    } else if (!userName) {
      toast.error("enter valid name");
    } else if (!regexALPHA.test(userName)) {
      toast.error("enter valid name");
    }else if (user_name === "undefined") {
      toast.error("enter valid username");
    } else if (!user_name) {
      toast.error("enter valid username without space");
    } else if (!regexALPHAONLY.test(user_name)) {
      toast.error("enter valid username without space");
    }
     else if (!regexEmail.test(email)) {
      toast.error("enter valid email");
    } else if (!regexMobile.test(mobile)) {
      toast.error("enter valid mobile");
    } else if (!regexPassword.test(password)) {
      toast.error("Enter Password password at least 6 character");
    } else if (
      address === "" ||
      address === undefined ||
      address === "undefined"
    ) {
      toast.error("enter valid address");
    } else if (
      statusId === "" ||
      statusId === undefined ||
      statusId === "undefined"
    ) {
      toast.error("select status");
    } else if (!userImage) {
      toast.error("please select an image");
    } else {
      console.log("user Data", data);
      formData.append("userType", designationId);
      formData.append("fps_id", fpcId);
      formData.append("employee_name", userName);
      formData.append("user_name", user_name);
      formData.append("email", email);
      formData.append("mobile_no", mobile);
      formData.append("password", password);
      formData.append("address_line1", address);
      formData.append("status", statusId);
      formData.append("user_profile_pic", userImage);
      setIsLoading(true);
      createUserAPI(formData, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            getUser(); //hit user list api
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setIsLoading(false);
        });
    }
  };
  const handleUserUpdate = () => {};
  const handleStateChange = (e) => {
    setDesignationId(e.target.value);
    if (e.target.value === 2) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  const handleDesignationChange = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setDesignationId(e.target.value);
    if (e.target.value === "O" || e.target.value === "V") {
      setFpcShow(true);
      console.log("Show List");
    } else {
      setFpcShow(false);
      setFpcId("");
      console.log("Hide List");
    }
  };
  const saveUserImage = (e) => {
    // console.log("Cool-I", commodityName);
    setUserImage(e.target.files[0]);
  };
  // Pagination
  const totalPages = Math.ceil(userList.length / itemsPerPage); // Total number of pages
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      {isLoading ? <FullScreenLoader /> : ""}
      <TopNav />
      <SideNavBar />
      {userLoader ? (
        <div className="p-5 " style={{}}>
          <p style={{ color: "crimson", textAlign: "center" }}>
            {"Unauthorized"}
          </p>
          <p style={{ textAlign: "center" }}>
            You will be redirected to the home page in{" "}
            <b style={{ color: "crimson" }}>{timer} </b> seconds.
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="wrapper">
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <ol className="breadcrumb float-sm-right">
                  {user.profile_data.user_type === "S" ? (
                    <li className="breadcrumb-item">
                      <Link
                        to={`${"/fpc-list"}`}
                        style={{ textDecoration: "none" }}
                      >
                        Home
                      </Link>
                    </li>
                  ) : (
                    <li className="breadcrumb-item">
                      <Link
                        to={`${"/dashboard"}/${
                          user.profile_data.user_type === "E"
                            ? "admin"
                            : user.profile_data.user_type === "V"
                            ? "officer"
                            : user.profile_data.user_type === "O"
                            ? "field-officer"
                            : user.profile_data.user_type === "S"
                            ? "state-officer"
                            : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Home
                      </Link>
                    </li>
                  )}
                  <li className="breadcrumb-item active">user</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          <Button
            className="ml-3 mb-3"
            variant="primary"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            Add User
          </Button>
          {/* /.content-header */}
          {/* Main content */}

          <section className="content">
            <div className="container-fluid">
              {/* Dashboard card component */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <section className="col-lg-12 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {/* <i className="fas fa-chart-pie mr-1" /> */}
                        <FaChartPie
                          style={{ with: 20, height: 20, marginRight: 5 }}
                        />
                        User List
                      </h4>
                    </div>
                    {/* /.card-header */}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr role="row" className="bg-secondary">
                            <th>S.No</th>
                            <th>User Type</th>
                            <th>FPC Name</th>
                            <th>Login ID</th>
                            <th>Employee Name</th>
                            <th>Mobile Number</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList
                            .slice(
                              (currentPage - 1) * itemsPerPage,
                              currentPage * itemsPerPage
                            )
                            .map((item, index) => (
                              <>
                                {item.user_type === "E" ? (
                                  ""
                                ) : (
                                  <tr key={index}>
                                    <td>
                                      {" "}
                                      {(currentPage - 1) * itemsPerPage +
                                        index +
                                        1}
                                    </td>

                                    <td
                                      value={item.user_type}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item.user_type_name}
                                    </td>
                                    <td
                                      value={item.fpc_id}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item.fpc_name}
                                    </td>
                                    <td style={{ textTransform: "" }}>
                                      {item.username}
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {item.employee_name}
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {item.mobile_no}
                                    </td>

                                    <td
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      {" "}
                                      <AiOutlineEye
                                        className="edit-button mr-3"
                                        onClick={() => handleShow(item)}
                                        style={{
                                          with: 20,
                                          height: 20,
                                        }}
                                      />
                                      {/* <RiDeleteBin4Line
                                        className="delete-button"
                                        // onClick={() => openDelete(item)}
                                        style={{ with: 20, height: 20 }}
                                      /> */}
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />

          {/* /.content */}
        </div>
        <aside className="control-sidebar control-sidebar-dark"></aside>
      </div>

      <Footer />
      <Modal show={show}>
        <Modal.Header>
          {!data.employee_name ? (
            <Modal.Title>Create User</Modal.Title>
          ) : (
            <Modal.Title>Update User</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Designation Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleDesignationChange}
                value={designationId}
              >
                <option value="">select one</option>
                {designationList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {/* {data.fpc_name != "Not Assigned" ? (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>FPC List</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setFpcId(e.target.value)}
                  value={fpcId}
                >
                  <option value="">Select One</option>
                  {fpcDataList.map((item, index) => (
                    <option key={index} value={item.fpc_id}>
                      {item.short_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : (
              "---"
            )} */}
            {fpcShow ? (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>FPC List</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setFpcId(e.target.value)}
                  value={fpcId}
                >
                  <option value="">Select One</option>
                  {fpcDataList.map((item, index) => (
                    <option key={index} value={item.fpc_id}>
                      {item.short_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : (
              ""
            )}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>User Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="username"
                value={user_name}
                onChange={(e) => setUser_Name(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="number"
                placeholder="mobile"
                maxLength={10}
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>

              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Active Status</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setStatusId(e.target.value)}
                value={statusId}
              >
                <option value="">Select One</option>
                {activeList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {showImg ? (
             <>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>User Profile Image</Form.Label>
                <br></br>
                <Image
                  style={{ width: 150, height: 60, cursor: "pointer" }}
                  src={`${BASE_URL_IMAGE}${data.user_profile_pic}`}
                  rounded
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Change User Profile Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  placeholder="user image"
                  // value={commodityName}
                  // onChange={(e) => setCommodityName(e.target.value)}
                  ref={inputRef}
                  onChange={saveUserImage}
                />
              </Form.Group></>
            ) : (
              ""
            )}
            {!showImg ? (
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Choose User Profile Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  placeholder="user image"
                  // value={commodityName}
                  // onChange={(e) => setCommodityName(e.target.value)}
                  ref={inputRef}
                  onChange={saveUserImage}
                />
              </Form.Group>
            ) : (
              ""
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!data.employee_name ? (
            <Button variant="primary" onClick={() => handleUserSave(data)}>
              Save
            </Button>
          ) : (
            ""
            // <Button variant="primary" onClick={() => handleUserUpdate(data)}>
            //   Update
            // </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
