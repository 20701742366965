import React, { useEffect, useRef, useState } from "react";
import { FiEdit, FiEye } from "react-icons/fi";

import { FaChartPie } from "react-icons/fa";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { RiBarChartFill } from "react-icons/ri";
import { FiUserPlus } from "react-icons/fi";
import HomeCard from "../card/HomeCard";
import { Link, useLocation } from "react-router-dom";
import { RiDeleteBin4Line } from "react-icons/ri";
import {
  approveDashboardTableDataAPI,
  getCategoryListAPI,
  getCommodityListAPI,
  getDashboardListDataAPI,
  getDistrictListAPI,
  getFpcListAPI,
  getGramPanchayatDropDownListAPI,
  getTableDataByBlockIdAndStatus,
  getTehsilDropDownListAPI,
  getVillageDropDownListAPI,
  rejectDashboardTableDataAPI,
  remarkListAPI,
} from "../../api/ApiCall";
import { toast } from "react-toastify";
import { Badge, Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { CiCircleRemove } from "react-icons/ci";
import { BASE_URL_IMAGE } from "../../constant/Constants";
import FullScreenLoader from "../loader/FullScreenLoader";

const TableDataTwo = (props) => {
  const [show, setShow] = useState(false);
  const [remark, setRemark] = useState("");
  const [reason, setReason] = useState("");
  const [openReject, setOpenReject] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [data, setData] = useState([]);
  const [imgShow, setImgShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const [genderList, setGenderList] = useState([
    { id: "Male", name: "Male" },
    { id: "Female", name: "Female" },
    { id: "Other", name: "Other" },
  ]);
  const [purposeList, setPurposeList] = useState([
    { id: "Buy", name: "Buy" },
    { id: "Sell", name: "Sell" },
  ]);
  const [purposeId, setPurposeId] = useState("");
  const [commodityList, setCommodityList] = useState([]);
  const [commodityId, setCommodityId] = useState("");
  const [farmerName, setFarmerName] = useState("");
  const [farmerAdhaar, setFarmerAdhaar] = useState("");
  const [farmerMobile, setFarmerMobile] = useState("");
  const [qtyKg, setQtyKg] = useState("");
  const [qtyMt, setQtyMt] = useState("");
  const [unitCost, setUnitCost] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [totalAmountWord, setTotalAmountWord] = useState("");
  const [noTransacton, setNoTransaction] = useState("");
  const [challanNo, setChallanNo] = useState("");
  const [genderId, setGenderId] = useState("");
  const [districtList, setDistrictList] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [tehsilList, setTehsilList] = useState([]);
  const [tehsilId, setTehsilId] = useState("");
  const [gramList, setGramList] = useState([]);
  const [gramId, setGramId] = useState("");
  const [villageList, setVillageList] = useState([]);
  const [villageId, setVillageId] = useState("");
  const [fpcList, setFpcList] = useState([]);
  const [fpcId, setFpcId] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [tableData, setTableData] = useState(props.data);
  const configRef = useRef(null);
  const [reasonList, setReasonList] = useState([]);
  const [reasonId, setReasonId] = useState("");
  const location = useLocation();
  const dataState = location.state;
  console.log("TAble===>", dataState);
  useEffect(() => {
    configRef.current = {
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + `${user.token}`,
      },
    };

    getTableDataByBlockId(dataState);
    commodityDataList();
    districtDataList();
    fpcDataList();
    getReasonList();
  }, []);
  const getReasonList = () => {
    remarkListAPI()
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setReasonList(res.data.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong");
      });
  };
  const fpcDataList = () => {
    getFpcListAPI(configRef.current)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          setFpcList(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getTableDataList = () => {
    setIsLoading(true);
    getDashboardListDataAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          //   setTableData(res.data.data);
          //hit data list api
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("500 internal server error");
      });
  };
  const commodityDataList = () => {
    setIsLoading(true);
    getCommodityListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setCommodityList(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const districtDataList = () => {
    setIsLoading(true);
    getDistrictListAPI(configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setDistrictList(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const handleShow = (item) => {
    console.log(item);
    // setFpcName(item.name_organization);
    setData(item);
    setShow(true);
  };
  const handleImgShow = (item) => {
    console.log(item);
    setImgShow(true);
    setShow(false);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleImgClose = () => {
    setImgShow(false);
    setShow(true);
  };
  const openDelete = (item) => {
    console.log("Reject", item);
    setOpenReject(true);
    setData(item);
  };
  const closeDelete = () => {
    setOpenReject(false);
    setReason("");
    setRemark("");
    setData([]);
  };
  const handleReject = () => {
    setIsLoading(true);
    const data1 = {
      id: data.id,
      reject_reason: reasonId,
      remark: remark,
    };
    // console.log("Dataa--->", data1);
    rejectDashboardTableDataAPI(data1, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setOpenReject(false);
          toast.success(res.data.message);
          getTableDataByBlockId(dataState);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("internal server error");
      });
  };
  const getTableDataByBlockId = (id) => {
    console.log("Data", id);
    getTableDataByBlockIdAndStatus(id, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          console.table("data", res.data.data);
          setTableData(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("somthing went wrong");
      });
  };
  const handleOpenUpdate = (item) => {
    setOpenUpdate(true);
    setData(item);
    setPurposeId(item.purpose_type);
    setTransactionDate(item.transation_date);
    setGenderId(item.gender);
    setFarmerName(item.farmer_name);
    setFarmerAdhaar(item.id_number);
    setFarmerMobile(item.mobile);
    setQtyKg(item.quantity_kg);
    setQtyMt(item.quantity_mt);
    setChallanNo(item.chalan_no);
    setNoTransaction(item.no_of_transaction);
    setTotalAmount(item.total_amount);
    setTotalAmountWord(item.total_amount_words);
    setUnitCost(item.unit_cost);
    setFpcId(item.fpc_id);
    setCommodityId(item.commodity_id);
    setDistrictId(item.district_id);
    setTehsilId(item.block_id);
    setGramId(item.panchayat_id);
    setVillageId(item.village_id);
    handleDistrictChange(item.district_id);
    handleTehsilChange(item.block_id);
    handleGramChange(item.panchayat_id);
  };
  const handleUpdateData = () => {
    const updateData = {
      fpc_id: fpcId,
      transation_date: transactionDate,
      purpose_type: purposeId,
      farmer_name: farmerName,
      gender: genderId,
      id_number: farmerAdhaar,
      mobile: farmerMobile,
      quantity_kg: qtyKg,
      quantity_mt: qtyMt,
      chalan_no: challanNo,
      no_of_transactions: noTransacton,
      total_amount: totalAmount,
      unit_cost: unitCost,
      commodity_id: commodityId,
      district_id: districtId,
      tehsil_id: tehsilId,
      gram_id: gramId,
      village_id: villageId,
    };
    console.log("Update--->", updateData);
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  const handleApprove = (item) => {
    setIsLoading(true);
    const data1 = {
      entry_id: item.id,
    };
    approveDashboardTableDataAPI(data1, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          getTableDataByBlockId(dataState);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("internal server error");
      });
    console.log("Approve", data1);
  };
  const handleDistrictChange = (e) => {
    setIsLoading(true);
    let sel = "";
    if (typeof e === "number") {
      sel = e;
    } else {
      sel = e.target.value;
    }
    setDistrictId(sel);
    const data = {
      district_id: sel,
    };
    getTehsilDropDownListAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setTehsilList(res.data.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  };
  const handleTehsilChange = (e) => {
    setIsLoading(true);
    let sel = "";
    if (typeof e === "number") {
      sel = e;
    } else {
      sel = e.target.value;
    }
    setTehsilId(sel);
    const data = {
      tehsil_id: sel,
    };
    getGramPanchayatDropDownListAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setGramList(res.data.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  };
  const handleGramChange = (e) => {
    setIsLoading(true);
    let sel = "";
    if (typeof e === "number") {
      sel = e;
    } else {
      sel = e.target.value;
    }
    setGramId(sel);
    const data = {
      panchayat_id: sel,
    };
    getVillageDropDownListAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          setIsLoading(false);
          setVillageList(res.data.data);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsLoading(false);
      });
  };
  console.log("Data from state-->", tableData);
  return (
    <div className="content-wrapper">
      {isLoading ? <FullScreenLoader /> : ""}
      {/* Content Header (Page header) */}
      <div className="container-fluid">
        <div className="row mb-2">
          {/* <div className="col-sm-6">
            <h1 className="m-0">Officer Dashboard</h1>
          </div> */}
          {/* /.col */}
          <div className="col-sm-12">
            <ol className="breadcrumb float-sm-right">
              {user.profile_data.user_type === "S" ? (
                <>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${"/fpc-list"}`}
                      style={{ textDecoration: "none" }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link
                      to={`${"/block-list"}/${dataState.fps_name}`}
                      style={{ textDecoration: "none" }}
                    >
                      block-list
                    </Link>
                  </li>
                </>
              ) : user.profile_data.user_type === "V" ? (
                <li className="breadcrumb-item">
                  <Link to={`${"/block-list"}`} className="">
                    <p>Home</p>
                  </Link>
                </li>
              ) : (
                <li className="breadcrumb-item">
                  <Link
                    to={`${"/dashboard"}/${
                      user.profile_data.user_type === "E"
                        ? "admin"
                        : user.profile_data.user_type === "V"
                        ? "officer"
                        : user.profile_data.user_type === "O"
                        ? "field-officer"
                        : user.profile_data.user_type === "S"
                        ? "state-officer"
                        : ""
                    }`}
                    style={{ textDecoration: "none" }}
                  >
                    Home
                  </Link>
                </li>
              )}
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          {/* Dashboard card component */}
          {/* Main row */}
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable">
              {/* Custom tabs (Charts with tabs)*/}
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    {/* <i className="fas fa-chart-pie mr-1" /> */}
                    <FaChartPie
                      style={{ with: 20, height: 20, marginRight: 5 }}
                    />
                    Recent Entries
                  </h4>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  <div className="table-responsive">
                    {tableData.length > 0 ? (
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr
                            role="row"
                            className="bg-secondary"
                            style={{ fontSize: "14px" }}
                          >
                            <th>{"S.No"}</th>
                            <th>{"Applicaiton No"}</th>
                            <th>{"FPC Name"}</th>
                            <th>{"Commodity Name"}</th>
                            <th>{"Mobile No."}</th>
                            <th>{"Farmer Name"}</th>
                            <th>{"QTY(kg)"}</th>
                            <th>{"Challan No."}</th>
                            <th>{"District"}</th>
                            <th>{"Status"}</th>
                            <th>{"Action"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((item, index) => (
                            <tr
                              style={{
                                fontSize: "12px",
                                textAlign: "center",
                                verticalAlign: "center",
                              }}
                              key={index}
                            >
                              <td>{index + 1}</td>
                              <td>{item.application_no}</td>
                              <td value={item.fpc_id}>{item.fpc_name}</td>
                              <td value={item.commodity_name}>
                                {item.commodity_name}
                              </td>
                              <td value={item.mobile}>{item.mobile}</td>
                              <td value={item.farmer_name}>
                                {item.farmer_name}
                              </td>
                              <td value={item.quantity_kg}>
                                {item.quantity_kg}
                              </td>
                              <td value={item.chalan_no}>{item.chalan_no}</td>
                              <td value={item.district}>{item.district}</td>
                              <td value={item.status}>
                                <span
                                  style={{
                                    backgroundColor:
                                      item.status === "Pending"
                                        ? "#ffc107"
                                        : item.status === "Approved"
                                        ? "#28a745"
                                        : "crimson",
                                    padding: "3px",
                                    borderRadius: "3px",
                                    color: "#fff",
                                    fontSize: "12px",
                                  }}
                                >
                                  {" "}
                                  {item.status}
                                </span>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {user.profile_data.user_type === "E" ? (
                                  <>
                                    <FiEye
                                      className="edit-button mr-3"
                                      onClick={() => handleShow(item)}
                                      style={{
                                        width: 20,
                                        height: 20,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </>
                                ) : user.profile_data.user_type === "V" ? (
                                  <>
                                    {item.status === "Pending" ? (
                                      <div style={{ display: "flex" }}>
                                        <FiEye
                                          className="edit-button m-1"
                                          onClick={() => handleShow(item)}
                                          style={{
                                            width: 22,
                                            height: 30,
                                            cursor: "pointer",
                                          }}
                                        />
                                        <AiOutlineCheckCircle
                                          className="edit-button m-1"
                                          onClick={() => handleApprove(item)}
                                          style={{
                                            width: 22,
                                            height: 30,
                                            cursor: "pointer",
                                          }}
                                        />
                                        <CiCircleRemove
                                          className="delete-button m-1"
                                          onClick={() => openDelete(item)}
                                          style={{
                                            width: 22,
                                            height: 30,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        {" "}
                                        <FiEye
                                          className="edit-button mr-3"
                                          onClick={() => handleShow(item)}
                                          style={{
                                            width: 20,
                                            height: 20,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </>
                                    )}
                                  </>
                                ) : user.profile_data.user_type === "O" ? (
                                  <>
                                    {item.status === "Pending" ? (
                                      <>
                                        <FiEye
                                          className="edit-button mr-3"
                                          onClick={() => handleShow(item)}
                                          style={{
                                            width: 22,
                                            height: 30,
                                            cursor: "pointer",
                                          }}
                                        />
                                        {/* <FiEdit
                                          className="edit-button mr-3"
                                          onClick={() => handleOpenUpdate(item)}
                                          style={{
                                            with: 20,
                                            height: 20,
                                          }}
                                        /> */}
                                      </>
                                    ) : (
                                      <>
                                        <div
                                          className="d-flex"
                                          style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <FiEye
                                            className="edit-button mr-3"
                                            onClick={() => handleShow(item)}
                                            style={{
                                              width: 22,
                                              height: 30,
                                              cursor: "pointer",
                                            }}
                                          />
                                          {/* <span style={{ textAlign: "center" }}>
                                            {" "}
                                            {item.remark}
                                          </span> */}
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : user.profile_data.user_type === "S" ? (
                                  <>
                                    <FiEye
                                      className="edit-button mr-3"
                                      onClick={() => handleShow(item)}
                                      style={{
                                        width: 20,
                                        height: 20,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <h6 style={{ color: "crimson", textAlign: "center" }}>
                        {"No records to display"}
                      </h6>
                    )}
                  </div>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </section>

            {/* /.Left col */}
          </div>
          {/* /.row (main row) */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
      <Modal size="xl" show={show}>
        <Modal.Header style={{ borderBottom: "1px solid silver" }}>
          <Modal.Title className="w-100">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <h2 style={{ fontSize: "1.4rem" }}>Detailed View</h2>{" "}
              {/* <span
                style={{
                  backgroundColor:
                    data.status === "Pending"
                      ? "#ffc107"
                      : data.status === "Approved"
                      ? "#28a745"
                      : "crimson",
                  padding: "3px",
                  borderRadius: "3px",
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                {" "}
                {data.status}
              </span> */}
              <h2 className="" style={{ fontSize: "1.4rem" }}>
                Application Number :{" "}
                <span style={{ color: "crimson" }}>{data.application_no}</span>
              </h2>
              <Badge
                style={{ lineHeight: "32px", fontSize: "16px" }}
                bg={
                  data.status === "Approved"
                    ? "success"
                    : data.status === "Pending"
                    ? "warning"
                    : "danger"
                }
              >
                {data.status}
              </Badge>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0.5rem 1rem 0.5rem 1rem" }}>
          <Row>
            <Col className="col-md-6">
              <div className="card " style={{ backgroundColor: "#e1e1e1db" }}>
                <div
                  className="card-body"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <h4 className="silver">Field Officer</h4>
                  {/* <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      {" "}
                      <p>{"FPC Name"}</p>
                      <p>{"Transaction Date"}</p>
                      <p>{"Purpose Type"}</p>
                    </div>
                    <div>
                      {" "}
                      <p>{data.fpc_name}</p>
                      <p>{data.transation_date}</p>
                      <p>{data.purpose_type}</p>
                    </div>
                  </div> */}
                  <table className="custome-table">
                    <tbody>
                      <tr>
                        <td className="custome-table-lable-cell">
                          FPC Name {" : "}
                        </td>
                        <td>{data.fpc_name}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          Transaction Date {" : "}
                        </td>
                        <td>{data.transation_date}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          Purpose Type {" : "}
                        </td>
                        <td>{data.purpose_type}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
            <Col className="col-md-6">
              <div className="card " style={{ backgroundColor: "#e1e1e1db" }}>
                <div
                  className="card-body"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <h4 className="silver">Farmer Personal Details</h4>
                  <table className="custome-table">
                    <tbody>
                      <tr>
                        <td className="custome-table-lable-cell">
                          Farmer Name {" : "}
                        </td>
                        <td>{data.farmer_name}</td>
                      </tr>
                      {/* <tr>
                        <td className="custome-table-lable-cell">
                          Gender {" : "}
                        </td>
                        <td>{data.gender}</td>
                      </tr> */}
                     {data.id_number?
                      <tr>
                      <td className="custome-table-lable-cell">
                        Adhaar No. {" : "}
                      </td>
                      <td>{data.id_number}</td>
                    </tr>:""}
                    {data.mobile ?  <tr>
                        <td className="custome-table-lable-cell">
                          Mobile No. {" : "}
                        </td>
                        <td>{data.mobile}</td>
                      </tr>:""}
                    </tbody>
                  </table>
                </div>
                {/* <div className="card-body">
                  <h4>Farmer Personal Details</h4>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      {" "}
                      <p>{"Farmer Name"}</p>
                      <p>{"Gender"}</p>
                      <p>{"Adhaar No."}</p>
                      <p>{"Mobile No."}</p>
                    </div>
                    <div>
                      {" "}
                      <p>{data.farmer_name}</p>
                      <p>{data.gender}</p>
                      <p>{data.id_number}</p>
                      <p>{data.mobile}</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </Col>
            <Col className="col-md-6">
              <div className="card " style={{ backgroundColor: "#e1e1e1db" }}>
                <div
                  className="card-body"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <h4 className="silver">Geographical Details</h4>
                  <table className="custome-table">
                    <tbody>
                      <tr>
                        <td className="custome-table-lable-cell">
                          District {" : "}
                        </td>
                        <td>{data.district}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          Block {" : "}
                        </td>
                        <td>{data.block}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          Gram Panchayat {" : "}
                        </td>
                        <td>{data.panchayat}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          Village {" : "}
                        </td>
                        <td>{data.village_name}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <div className="card-body">
                  <h4>Geographical Details</h4>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      {" "}
                      <p>{"District"}</p>
                      <p>{"Block"}</p>
                      <p>{"Gram Panchayat"}</p>
                      <p>{"Village"}</p>
                    </div>
                    <div style={{ float: "left" }}>
                      {" "}
                      <p>{data.district}</p>
                      <p>{data.block}</p>
                      <p>{data.panchayat}</p>
                      <p>{data.village_name}</p>
                    </div>
                  </div>
                </div> */}
                {data.reject_reason_name === "" ? (
                  ""
                ) : (
                  <div
                    className="card-body"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <h4 className="silver">Remark Details</h4>
                    <table className="custome-table">
                      <tbody>
                        <tr>
                          <td className="custome-table-lable-cell">
                            Reason of Rejection {" : "}
                          </td>
                          <td>{data.reject_reason_name}</td>
                        </tr>
                        <tr>
                          <td className="custome-table-lable-cell">
                            Remark {" : "}
                          </td>
                          <td>{data.remark}</td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>
                        <p>Reason of Rejection</p>
                        <p>Remark</p>
                      </div>
                      <div>
                        <p>{data.reject_reason_name}</p>
                        <p>{data.remark}</p>
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
            </Col>
            <Col className="col-md-6">
              <div className="card " style={{ backgroundColor: "#e1e1e1db" }}>
                <div
                  className="card-body"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <h4 className="silver">Commodity Details</h4>
                  <table className="custome-table">
                    <tbody>
                      <tr>
                        <td className="custome-table-lable-cell">
                          {"Commodity Name"} {" : "}
                        </td>
                        <td>{data.commodity_name}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          {"Quantity (KG)"} {" : "}
                        </td>
                        <td>{data.quantity_kg}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          {"Quantity (MT)"} {" : "}
                        </td>
                        <td>{data.quantity_mt}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          {"Unit Cost/KG"} {" : "}
                        </td>
                        <td>{data.unit_cost}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          {"Total Amount(Rs)"} {" : "}
                        </td>

                        <td>{data.total_amount}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          {"Total Amount(Word)"} {" : "}
                        </td>

                        <td className="custome-table-lable-data">
                          {data.total_amount_words}
                        </td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          {"No of Transaction"} {" : "}
                        </td>
                        <td>1{/* {data.no_of_transaction} */}</td>
                      </tr>
                      <tr>
                        <td className="custome-table-lable-cell">
                          {"Challan No"} {" : "}
                        </td>
                        <td>{data.chalan_no}</td>
                      </tr>
                     {data.chalan_image ? 
                      <tr>
                      <td className="custome-table-lable-cell">
                        Challan Image {" : "}
                      </td>
                      <td>
                        {" "}
                        <Image
                          style={{
                            width: 150,
                            height: 60,
                            cursor: "pointer",
                          }}
                          src={`${BASE_URL_IMAGE}${data.chalan_image}`}
                          rounded
                          onClick={() => handleImgShow()}
                        />
                      </td>
                    </tr>:""}
                    </tbody>
                  </table>
                  {/* <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      {" "}
                      <p>{"Commodity Name"}</p>
                      <p>{"Quantity (KG)"}</p>
                      <p>{"Quantity (MT)"}</p>
                      <p>{"Unit Cost/KG"}</p>
                      <p>{"Total Amount(Rs)"}</p>
                      <p>{"Total Amount(word)"}</p>
                      <p>{"No of Transaction"}</p>
                      <p>{"Challan No"}</p>
                    </div>
                    <div>
                      {" "}
                      <p>{data.commodity_name}</p>
                      <p>{data.quantity_kg}</p>
                      <p>{data.quantity_mt}</p>
                      <p>{data.unit_cost}</p>
                      <p>{data.total_amount}</p>
                      <p>{data.total_amount_words}</p>
                      <p>{data.no_of_transaction}</p>
                      <p>{data.chalan_no}</p>
                      <Image
                        style={{ width: 150, height: 60, cursor: "pointer" }}
                        src={`${BASE_URL_IMAGE}${data.chalan_image}`}
                        rounded
                        onClick={() => handleImgShow()}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </Col>
            {/* {data.remark === null ? (
              ""
            ) : ( */}
            {/* <Col className="col-md-6">
              <div className="card ">
                <div className="card-body">
                  <h4>Remark Details</h4>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <p>Reason of Rejection</p>
                      <p>Remark</p>
                    </div>
                    <div>
                      <p>{data.reject_reason_name}</p>
                      <p>{data.remark}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
            {/* )} */}
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ padding: "0.5rem 1rem 0.5rem 1rem" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="xl" show={imgShow}>
        <Modal.Header>
          <Modal.Title>{"Challan View"}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <Image
            style={{ width: "100%", height: 460 }}
            src={`${BASE_URL_IMAGE}${data.chalan_image}`}
            rounded
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleImgClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size="lg" show={openReject}>
        <Modal.Header>
          <Modal.Title>{"Reject"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Reject Reason</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setReasonId(e.target.value)}
                value={reasonId}
              >
                <option value="">Select One</option>
                {reasonList.map((item, index) => (
                  <option key={index} value={item.remark_id}>
                    {item.reject_reason}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                type="text"
                placeholder="remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                autoFocus
              />
            </Form.Group> */}

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Remark</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="remark"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={handleReject}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
      {/* UPDATE MODEL FOR FIELD OFFICER */}
      <Modal size="xl" show={openUpdate}>
        <Modal.Header>
          <Modal.Title className="w-100">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <h2>Detailed View</h2>{" "}
              {/* <span
                style={{
                  backgroundColor:
                    data.status === "Pending"
                      ? "#ffc107"
                      : data.status === "Approved"
                      ? "#28a745"
                      : "crimson",
                  padding: "3px",
                  borderRadius: "3px",
                  color: "#fff",
                  fontSize: "12px",
                }}
              >
                {" "}
                {data.status}
              </span> */}
              <h4 className="">
                Application Number :{" "}
                <span style={{ color: "crimson" }}>{data.application_no}</span>
              </h4>
              <Badge
                style={{ lineHeight: "32px", fontSize: "16px" }}
                bg={
                  data.status === "Approved"
                    ? "success"
                    : data.status === "Pending"
                    ? "warning"
                    : "danger"
                }
              >
                {data.status}
              </Badge>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="col-md-6">
              <div className="card ">
                <div className="card-body">
                  <h5>Field Officer</h5>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      {" "}
                      <p>{"FPC Name"}</p>
                      <p>{"Transaction Date"}</p>
                      <p>{"Purpose Type "}</p>
                    </div>
                    <div>
                      {" "}
                      <Form.Select
                        aria-label="Default select example"
                        // onChange={handleDistrictChange}
                        onChange={(e) => setFpcId(e.target.value)}
                        value={fpcId}
                      >
                        <option value="">Select One</option>
                        {fpcList.map((item, index) => (
                          <option key={index} value={item.fpc_id}>
                            {item.fpc_name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Group>
                        <Form.Control
                          placeholder="transaction date"
                          value={transactionDate}
                          onChange={(e) => setTransactionDate(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Select
                        aria-label="Default select example"
                        // onChange={handleCategoryChange}
                        // (e) => setCategory(e.target.value)}
                        onChange={(e) => setPurposeId(e.target.value)}
                        value={purposeId}
                      >
                        <option value="">Select One</option>
                        {purposeList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="col-md-6">
              <div className="card ">
                <div className="card-body">
                  <h4>Farmer Personal Details</h4>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      {" "}
                      <p>{"Farmer Name"}</p>
                      <p>{"Gender"}</p>
                      <p>{"Adhaar No."}</p>
                      <p>{"Mobile No."}</p>
                    </div>
                    <div>
                      {" "}
                      <Form.Group>
                        <Form.Control
                          placeholder="farmer name"
                          value={farmerName}
                          onChange={(e) => setFarmerName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Select
                        aria-label="Default select example"
                        // onChange={handleCategoryChange}
                        // (e) => setCategory(e.target.value)}
                        onChange={(e) => setGenderId(e.target.value)}
                        value={genderId}
                      >
                        <option value="">Select One</option>
                        {genderList.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Group>
                        <Form.Control
                          placeholder="Adhaar name"
                          value={farmerAdhaar}
                          onChange={(e) => setFarmerAdhaar(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          placeholder="mobile number"
                          value={farmerMobile}
                          onChange={(e) => setFarmerMobile(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="col-md-6">
              <div className="card ">
                <div className="card-body">
                  <h4>Geographical Details</h4>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      {" "}
                      <p>{"District"}</p>
                      <p>{"Block"}</p>
                      <p>{"Gram Panchayat"}</p>
                      <p>{"Village"}</p>
                    </div>
                    <div>
                      {" "}
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleDistrictChange}
                        // onChange={(e) => setCommodityId(e.target.value)}
                        value={districtId}
                      >
                        <option value="">Select One</option>
                        {districtList.map((item, index) => (
                          <option key={index} value={item.district_id}>
                            {item.district_name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleTehsilChange}
                        // onChange={(e) => setCommodityId(e.target.value)}
                        value={tehsilId}
                      >
                        <option value="">Select One</option>
                        {tehsilList.map((item, index) => (
                          <option key={index} value={item.tehsil_id}>
                            {item.tehsil_name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={handleGramChange}
                        // onChange={(e) => setCommodityId(e.target.value)}
                        value={gramId}
                      >
                        <option value="">Select One</option>
                        {gramList.map((item, index) => (
                          <option key={index} value={item.panchayat_id}>
                            {item.panchayat_name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Select
                        aria-label="Default select example"
                        // onChange={handleGramChange}
                        onChange={(e) => setVillageId(e.target.value)}
                        value={villageId}
                      >
                        <option value="">Select One</option>
                        {villageList.map((item, index) => (
                          <option key={index} value={item.village_id}>
                            {item.village_name}
                          </option>
                        ))}
                      </Form.Select>
                      <p>{data.village}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="col-md-6">
              <div className="card ">
                <div className="card-body">
                  <h4>Commodity Details</h4>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      {" "}
                      <p>{"Commodity Name"}</p>
                      <p>{"Quantity (KG)"}</p>
                      <p>{"Quantity (MT)"}</p>
                      <p>{"Unit Cost/KG"}</p>
                      <p>{"Total Amount(Rs)"}</p>
                      <p>{"Total Amount(word)"}</p>
                      <p>{"No of Transaction"}</p>
                      <p>{"Challan No"}</p>
                    </div>
                    <div>
                      {" "}
                      <Form.Select
                        aria-label="Default select example"
                        // onChange={handleCategoryChange}
                        // (e) => setCategory(e.target.value)}
                        onChange={(e) => setCommodityId(e.target.value)}
                        value={commodityId}
                      >
                        <option value="">Select One</option>
                        {commodityList.map((item, index) => (
                          <option key={index} value={item.commodity_id}>
                            {item.commodity_name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Group>
                        <Form.Control
                          placeholder="mobile number"
                          value={qtyKg}
                          onChange={(e) => setQtyKg(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          placeholder="mobile number"
                          value={qtyMt}
                          onChange={(e) => setQtyMt(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          placeholder="mobile number"
                          value={unitCost}
                          onChange={(e) => setUnitCost(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          placeholder="mobile number"
                          value={totalAmount}
                          onChange={(e) => setTotalAmount(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          placeholder="mobile number"
                          value={totalAmountWord}
                          onChange={(e) => setTotalAmountWord(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          placeholder="mobile number"
                          value={noTransacton}
                          onChange={(e) => setNoTransaction(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          placeholder="mobile number"
                          value={challanNo}
                          onChange={(e) => setChallanNo(e.target.value)}
                        />
                      </Form.Group>
                      <Image
                        style={{ width: 150, height: 60, cursor: "pointer" }}
                        src={`${BASE_URL_IMAGE}${data.chalan_image}`}
                        rounded
                        onClick={() => handleImgShow()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdate}>
            Close
          </Button>
          <Button variant="success" onClick={handleUpdateData}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TableDataTwo;
