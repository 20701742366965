import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FullScreenLoader from "../../components/loader/FullScreenLoader";
import TopNav from "../../components/navbar/TopNav";
import SideNavBar from "../../components/sidebar/SideNavBar";
import { FaChartPie } from "react-icons/fa";
import { Button, Form, Modal } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import {
  addKycAPI,
  deleteKycAPI,
  getKycListAPI,
  updateKycAPI,
} from "../../api/ApiCall";
import { toast } from "react-toastify";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin4Line } from "react-icons/ri";
import { regexALPHA } from "../../constant/Constants";

const Kyc = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userLoader, setUserLoader] = useState(false);
  const [timer, setTimer] = useState(5);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [kycName, setKycName] = useState("");
  const [kycDataList, setKycDataList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const configRef = useRef(null);
  useEffect(() => {
    if (user === null) {
      setUserLoader(true);
      setIsLoading(true);
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      // redirect to home page when the timer reaches 0
      if (timer === 0) {
        navigate("/login");
      }
      // clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } else {
      configRef.current = {
        headers: {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + `${user.token}`,
        },
      };
      setUserLoader(false);
      setUserLoader(false);
    }
  }, [timer]);
  useEffect(() => {
    kycList();
  }, []);
  const kycList = () => {
    setIsLoading(true);
    getKycListAPI(configRef.current)
      .then((res) => {
        if (res.data.status) {
          setIsLoading(false);
          setKycDataList(res.data.data);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleShow = (item) => {
    setKycName(item.kyc_name);
    setData(item);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setKycName("");
    setData([]);
  };
  const handleKycSave = (item) => {
    if (!regexALPHA.test(kycName)) {
      toast.error("please enter a valid kyc name");
    } else if (!kycName) {
      toast.error("please enter a valid kyc name");
    } else if (kycName === "undefined" || kycName === undefined) {
      toast.error("please enter a valid kyc name");
    } else if (kycName === "null" || kycName === null) {
      toast.error("please enter a valid kyc name");
    } else {
      setIsLoading(true);
      const data = {
        kyc_name: kycName,
      };
      addKycAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            kycList();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error();
          setIsLoading(false);
        });
    }
  };
  const handleKycUpdate = (item) => {
    if (!regexALPHA.test(kycName)) {
      toast.error("please enter a valid kyc name");
    } else if (!kycName) {
      toast.error("please enter a valid kyc name");
    } else if (kycName === "undefined" || kycName === undefined) {
      toast.error("please enter a valid kyc name");
    } else if (kycName === "null" || kycName === null) {
      toast.error("please enter a valid kyc name");
    } else {
      setIsLoading(true);
      const data = {
        kyc_id: item.kyc_id,
        kyc_name: kycName,
      };
      updateKycAPI(data, configRef.current)
        .then((res) => {
          if (res.data.status === 200) {
            handleClose();
            toast.success(res.data.message);
            kycList();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };
  const openDelete = (item) => {
    setIsOpen(true);
    setData(item);
    setKycName(item.kyc_name);
  };
  const closeDelete = () => {
    setIsOpen(false);
  };
  const handleKycDelete = (item) => {
    const data = {
      kyc_id: item.kyc_id,
    };
    setIsLoading(true);
    deleteKycAPI(data, configRef.current)
      .then((res) => {
        if (res.data.status === 200) {
          closeDelete();
          toast.success(res.data.message);
          kycList();
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);

        setIsLoading(false);
      });
  };
  return (
    <div>
      {isLoading ? <FullScreenLoader /> : ""}
      <TopNav />
      <SideNavBar />
      {userLoader ? (
        <div className="p-5 " style={{}}>
          <p style={{ color: "crimson", textAlign: "center" }}>
            {"Unauthorized"}
          </p>
          <p style={{ textAlign: "center" }}>
            You will be redirected to the home page in{" "}
            <b style={{ color: "crimson" }}>{timer} </b> seconds.
          </p>
        </div>
      ) : (
        ""
      )}
      <div className="wrapper">
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <ol className="breadcrumb float-sm-right">
                  {user.profile_data.user_type === "S" ? (
                    <li className="breadcrumb-item">
                      <Link
                        to={`${"/fpc-list"}`}
                        style={{ textDecoration: "none" }}
                      >
                        Home
                      </Link>
                    </li>
                  ) : (
                    <li className="breadcrumb-item">
                      <Link
                        to={`${"/dashboard"}/${
                          user.profile_data.user_type === "E"
                            ? "admin"
                            : user.profile_data.user_type === "V"
                            ? "officer"
                            : user.profile_data.user_type === "O"
                            ? "field-officer"
                            : user.profile_data.user_type === "S"
                            ? "state-officer"
                            : ""
                        }`}
                        style={{ textDecoration: "none" }}
                      >
                        Home
                      </Link>
                    </li>
                  )}
                  <li className="breadcrumb-item active">kyc</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          <Button
            className="ml-3 mb-3"
            variant="primary"
            style={{ cursor: "pointer" }}
            onClick={handleShow}
          >
            Add KYC
          </Button>
          {/* /.content-header */}
          {/* Main content */}

          <section className="content">
            <div className="container-fluid">
              {/* Dashboard card component */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <section className="col-lg-12 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        {/* <i className="fas fa-chart-pie mr-1" /> */}
                        <FaChartPie
                          style={{ with: 20, height: 20, marginRight: 5 }}
                        />
                        KYC List
                      </h4>
                    </div>
                    {/* /.card-header */}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr role="row" className="bg-secondary">
                            <th>S.No</th>
                            <th>Service</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {kycDataList.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>

                              <td style={{ textTransform: "capitalize" }}>
                                {item.kyc_name}
                              </td>

                              <td
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                <FiEdit
                                  className="edit-button mr-3"
                                  onClick={() => handleShow(item)}
                                  style={{
                                    with: 20,
                                    height: 20,
                                  }}
                                />
                                <RiDeleteBin4Line
                                  className="delete-button"
                                  onClick={() => openDelete(item)}
                                  style={{ with: 20, height: 20 }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          {/* <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            /> */}

          {/* /.content */}
        </div>
        <aside className="control-sidebar control-sidebar-dark"></aside>
      </div>

      <Footer />
      <Modal show={show}>
        <Modal.Header>
          {!data.kyc_name ? (
            <Modal.Title>Add KYC</Modal.Title>
          ) : (
            <Modal.Title>Update KYC</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>KYC Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="kyc name"
                value={kycName}
                onChange={(e) => setKycName(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!data.kyc_name ? (
            <Button variant="primary" onClick={() => handleKycSave(data)}>
              Save
            </Button>
          ) : (
            <Button variant="primary" onClick={() => handleKycUpdate(data)}>
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isOpen}>
        <Modal.Header>
          <Modal.Title>
            <Modal.Title>Delete KYC</Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>KYC Name</Form.Label>
              <Form.Control
                style={{ borderColor: "crimson" }}
                type="text"
                placeholder="kyc name"
                value={kycName}
                autoFocus
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDelete}>
            Close
          </Button>

          <Button variant="danger" onClick={() => handleKycDelete(data)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Kyc;
